/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import modalStyles from '../../css/Modal.module.css';
import DragAndDrop from './DragAndDrop';
import { Button, Modal } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const UploadFileModal = ({open, handleClose, onSubmit, accept}) => {
    const { id: courseId } = useParams()

	const [file, setFile] = useState(null);

	const handleUpload = (file) => {
		setFile(file);
	}

    const handleFileUpload = async (file) => {
        if (!file) {
            return
        }

        try {
            await onSubmit(file);
            handleClose();
            setFile(null);
        } catch (error) {
            console.error('Error uploading file: ', error)
            // alert('File not uploaded!')
        }
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className={`${modalStyles['container']}`}>
                <div className={`${modalStyles['top-container']}`}>
                    <h1 className={`${modalStyles['heading']}`}>Upload File</h1>
                    <ClearIcon 
                        onClick={handleClose}
                        sx={{
                            fontSize: "2.5em",
                            cursor: "pointer"
                        }}
                    />
                </div>
                <div className={`${modalStyles['main-container']}`}>
                    <DragAndDrop 
                        file={file}
                        accept={accept}
                        handleUpload={handleUpload} 
                    />
                </div>
                <div className={`${modalStyles['button-container']}`}>
                    <Button
                        className={`btn btn-primary ${modalStyles['add-to-course-btn']}`}
                        onClick={() => handleFileUpload(file)}
                        variant='contained'
                        size='large'
                    >
                        Upload
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default UploadFileModal