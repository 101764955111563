/*eslint-disable*/
import React, { useEffect, useRef, useState } from 'react';
import modalStyles from '../../css/Modal.module.css';
import { Button, Modal } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Document } from 'react-pdf'
import fileStyles from './Files.module.css'

const DeleteFileModal = ({open, handleClose, file, onDelete, getFiles}) => {
    const getFileName = (fileUrl) => {
        if (!fileUrl) {
            return '';
        }

		const splitUrl = fileUrl.split('/');
		return splitUrl[splitUrl.length - 1];
	}
    
	const handleConfirmDelete = () => {
        if (!file) return

        try {
            console.log("HANDLE CONFIRM DELETE")
            onDelete(file);
            handleClose();
        } catch (error) {
            console.error('Error deleting file: ', error)
        }
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className={`${modalStyles['container']}`}>
                <div className={`${modalStyles['top-container']}`}>
                    <h1 className={`${modalStyles['heading']}`}>Delete File</h1>
                    <ClearIcon 
                        onClick={handleClose}
                        sx={{
                            fontSize: "2.5em",
                            cursor: "pointer"
                        }}
                    />
                </div>
                <div className={`${modalStyles['main-container']}`}>
                    Are you sure you want to delete <b>{getFileName(file)}</b>?
                </div>
                <div className={`${modalStyles['button-container']}`}>
                    <Button
                        className={`btn btn-primary ${modalStyles['add-to-course-btn']}`}
                        onClick={handleConfirmDelete}
                        variant='contained'
                        size='large'
                    >
                        Delete
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default DeleteFileModal