/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { Divider, Fab, FormControlLabel, Skeleton, Switch, Tab, Tabs, Tooltip } from '@mui/material';
import globalStyles from '../../css/Page.module.css';
import styles from './Courses.module.css';
import Announcements from './tabs/announcements/Announcements';
import Modules from './tabs/modules/Modules';
import Quizzes from './tabs/quizzes/Quizzes';
import Assignments from './tabs/Assignments';
import People from './tabs/People';
import Syllabus from './tabs/syllabus/Syllabus';
import GradeAnalyzerNew from '../GradeAnalyzerNew';
import SettingsIcon from '@mui/icons-material/Settings';
import axios from 'axios';
import { Redirect, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import EditCourse from './EditCourse';
import { InfoRounded } from '@mui/icons-material';
import CourseInfo from './CourseInfo';

const Course = () => {
    const [isEditCourseModalOpen, setIsEditCourseModalOpen] = useState(false);
    const { id: courseId } = useParams();
    const [course, setCourse] = useState();
    const [isLoading, setIsLoading] = useState(true);
    const [role, setRole] = useState("");
    
    const tabs = [
        "Announcements", 
        "Syllabus", 
        "Modules", 
        // "Assignments", 
        "Quizzes", 
        "Grade Analyzer", 
        "People"
    ];
    const tabComponents = [
        <Announcements isLoading={isLoading} role={role} />, 
        <Syllabus isLoading={isLoading} role={role} />, 
        <Modules isLoading={isLoading} role={role} />, 
        // <Assignments isLoading={isLoading} />, 
        <Quizzes isLoading={isLoading} role={role} />, 
        <GradeAnalyzerNew isLoading={isLoading} />, 
        <People isLoading={isLoading} />, 
    ];
    const [activeTab, setActiveTab] = useState(JSON.parse(localStorage.getItem("activeTab")) || 0);

    const handleChange = (event, value) => {
        setActiveTab(value);
        localStorage.setItem("activeTab", JSON.stringify(value));
    };

    const getCourse = async () => {
        // setTimeout(() => {
        //     setCourse({
        //         id: 1,
        //         name: "Web Development"
        //     })
        //     setIsLoading(false);
        // }, 5000);

        // GET API Call
        try {
            const res = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/api/v2/course/${courseId}`,
                headers: { 'Content-Type': 'application/json' }
            })
            setCourse(res.data);
            setIsLoading(false);
        } catch(error) {
            console.log('ERROR: ', error);
        }
    }

    const editCourse = async (name, desc, startDate, endDate) => {
		// POST API Call
		try {
			const res = await axios({
				method: 'patch',
				url: `${process.env.REACT_APP_BASE_URL}/api/v2/course/${courseId}`,
				data: {
					name: name,
					description: desc,
					startDate: startDate, 
					endDate: endDate,
					isPublished: course.isPublished,
				},
				headers: { 'Content-Type': 'application/json' }
			})
			getCourse();
		} catch(error) {
			console.log('ERROR: ', error);
		}
	}

    
    const deleteCourse = async (id) => {
        try {
            await axios({
                method: 'delete',
                url: `${process.env.REACT_APP_BASE_URL}/api/v2/course/${id}`,
                headers: { 'Content-Type': 'application/json' }
            })
            console.log("HI")
            window.location.href = "/courses"
        } catch(error) {
            console.log(error)
        }
    }


    const handlePublish = async (toBePublished) => {
		// POST API Call
		try {
			const res = await axios({
				method: 'patch',
				url: `${process.env.REACT_APP_BASE_URL}/api/v2/course/${courseId}`,
				data: {
					name: course.name,
					description: course.description,
					isPublished: toBePublished,
				},
				headers: { 'Content-Type': 'application/json' }
			})
			getCourse();
		} catch(error) {
			console.log('ERROR: ', error);
		}
	}

    const getRole = async () => {
        const curUserResponse = await axios.get(`${process.env.REACT_APP_BASE_URL}/user`);
        const curUser = curUserResponse.data;
        const response = await axios.get(
            `${process.env.REACT_APP_BASE_URL}/api/v2/courses/${courseId}/students`
        )
        const people = response.data.people;
        const filteredPeople = people.filter((person) => (person.email === curUser.email))
        

        if (filteredPeople.length === 0) {
            return;
        }

        setRole(filteredPeople[0].role);
    }

    useEffect(() => {
        getCourse();
        getRole();
    }, [])
    
    return (    
		<div className={`${globalStyles['main-container']} ${styles['main-container']}`}>
			<div className={`${globalStyles['main-heading']}`}>
				{isLoading ? <Skeleton className={`${globalStyles['main-heading-skeleton']}`} variant='text' /> : course.name}
                <div style={{display: "flex", gap: "0.5em", alignContent: "center", justifyContent: "center"}}>
                    {
                        !isLoading && role !== "" && role === "Instructor" &&
                        <FormControlLabel 
                            control={
                                <Switch
                                    checked={course?.isPublished}
                                    onChange={() => handlePublish(!course?.isPublished)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                    color='success'
                                />
                            } 
                            sx={{
                                margin: 0
                            }}
                            label="Publish" 
                            labelPlacement="start"
                        />
                    }
                    {
                        (!isLoading && role !== "") && (
                            role === "Instructor" ? (
                                <Tooltip title="Settings">
                                    <Fab aria-label="add" color="primary" disabled={isLoading}>
                                        <SettingsIcon
                                            onClick={() => {
                                                setIsEditCourseModalOpen(true);
                                            }}
                                        />
                                    </Fab>
                                </Tooltip>
                            ) : (
                                <Tooltip title="Information">
                                    <Fab aria-label="add" color="primary" disabled={isLoading}>
                                        <InfoRounded
                                            onClick={() => {
                                                setIsEditCourseModalOpen(true);
                                            }}
                                        />
                                    </Fab>
                                </Tooltip>
                            )
                        )
                    }
                </div>
			</div>
            <div className={`${styles['tabs']}`}>
                <Tabs variant="scrollable" scrollButtons="auto" value={activeTab} onChange={handleChange} aria-label="basic tabs example">
                    {
                        tabs.map((tab, ind) => (
                            <Tab className={`${styles['tab']}`} key={ind} label={tab} />
                        ))
                    }
                </Tabs>
            </div>
			<Divider />
            {
                tabComponents[activeTab]
            }
            {
                isEditCourseModalOpen && (
                    role === "Instructor" ? (
                        <EditCourse 
                            open={isEditCourseModalOpen} 
                            setOpen={setIsEditCourseModalOpen}
                            course={course} 
                            editCourse={editCourse}
                            deleteCourse={deleteCourse}
                        />
                    ) : (
                        <CourseInfo 
                            open={isEditCourseModalOpen} 
                            setOpen={setIsEditCourseModalOpen}
                            course={course} 
                        />                        
                    )
                )
            }
		</div>
	)
}

export default Course