/*eslint-disable*/
import React, { useEffect, useState } from 'react'
import styles from './CreateQuiz.module.css'
import ClearIcon from '@mui/icons-material/Clear';
import Modal from '@mui/material/Modal';
import { Button, Checkbox, CircularProgress, createTheme, Fab, FormControlLabel, Slider, TextField, ThemeProvider, Tooltip } from '@mui/material';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import AddIcon from '@mui/icons-material/Add';
import Question from './Question';
import NewQuestion from './NewQuestion';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Redirect } from 'react-router-dom';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import dayjs from 'dayjs';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { FormLabel } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';

const EditQuiz = ({open, setOpen, quizId, getQuizzes}) => {
	const [isGeneratingQuestions, setIsGeneratingQuestions] = useState(false);
	const { id: courseId } = useParams()
	const [quizTitle, setQuizTitle] = useState(null);
	const [quizSynopsis, setQuizSynopsis] = useState('');
	const [showAddQuestionForm, setShowAddQuestionForm] = useState(false);
	const [showGenerateQuestionForm, setShowGenerateQuestionForm] = useState(false);
	const [editQuestionIndex, setEditQuestionIndex] = useState(-1);
	const [questions, setQuestions] = useState([])
	const [timeLimit, setTimeLimit] = useState(dayjs('2022-04-17T01:00'))
	const [draggingQuestion, setDraggingQuestion] = useState(null)
	const [currentQuestion, setCurrentQuestion] = useState({
		type: "MCQ",
		statement: "",
		questionImages: [],
		options: [{
			id: 1,
			optionStatement: 'Option'
		}],
		correctAnswers: [],
		explanation: "",
		points: "1"
	})
	const [isValidTitle, setIsValidTitle] = useState(true);
	const [isValidTimeLimit, setIsValidTimeLimit] = useState(true);
	const [isValidQuestions, setIsValidQuestions] = useState(true);
    const [isPublished, setIsPublished] = useState(false);
	
	// FOR AI GENERATED QUESTIONS
	const [topic, setTopic] = useState('');
	const [isValidTopic, setIsValidTopic] = useState(true);
	const [noOfQues, setNoOfQues] = useState(5);
	const [isValidNoOfQues, setIsValidNoOfQues] = useState(true);
	const courseName = useSelector((state) => state.currentCourseData.courseName)
	// DIFFICULTY OF QUESTIONS (0 => EASY, 1 => MEDIUM, 2 => HARD)
	const [difficulty, setDifficulty] = useState(1);
	// ARRAY CONTAINING OF QUESTIONS TO BE GENERATED (CAN HAVE VALUES "TF", "MCQ", "SAT", "FITB")
	const [types, setTypes] = useState([]);

	useEffect(() => {
		setNoOfQues(Math.max(types.length,noOfQues))
	}, [types])
	
    useEffect(async ()=>{
        if (quizId) {
            const res = await axios.get(`${process.env.REACT_APP_BASE_URL}/quiz/${quizId}`)
            const quizObj = res.data
            const quiz = {
                title: quizObj.data.quizTitle,
                synopsis: quizObj.data.quizSynopsis,
                timeLimit: quizObj.timeLimit,
                questions: quizObj.data.questions.map((question, i) => ({
                        id: i,
                        type: (question.answerSelectionType === "single") ? "TF" : 
                          (question.answerSelectionType === "multiple") ? "MCQ" : 
                          (question.answerSelectionType === "fill in the blanks") ? "FITB" : "SAT",
                        statement: (question.question),
                        questionImages:[],
                        options: (question.answers.map((answer, i) => ({
                            ...answer,
                            optionImages: []
                        }))),
                        correctAnswers: question.correctAnswer,
                        explanation: question.explanation || '',
                        points: question.point
                    }           
                )),
                isPublished: quizObj.isPublished
            }
            setIsPublished(quiz.isPublished)
            setQuizTitle(quiz.title);
            setQuizSynopsis(quiz.synopsis);
            setQuestions(quiz.questions);
            const [hrs, mins, secs] = quiz.timeLimit.split(':').map(ele => parseInt(ele));
            const savedTimeLimit = dayjs().hour(hrs).minute(mins).second(secs);
            setTimeLimit(savedTimeLimit);
        }
    }, [quizId]);


	const validateQuiz = () => {
		var isValid = true;

		// title cannot be empty
		if (quizTitle === null || quizTitle.trim() === '') {
			isValid = false;
			setIsValidTitle(false);
			document.getElementById('quiz-title').scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" });
		}

		// timeLimit cannot be 00:00:00
		if (!timeLimit.hour() && !timeLimit.minute() && !timeLimit.second()) {
			isValid = false;
			setIsValidTimeLimit(false);
		}

		// questions array cannot be empty
		if (!questions.length) {
			isValid = false;
			setIsValidQuestions(false);
		}

		return isValid;
	}

	const handleSubmit = async () => {
		if (!validateQuiz()) {
			return;
		}

		const quiz = {
			isPublished: isPublished,
			courseId: courseId,
			timeLimit: `${timeLimit.hour() > 9 ? timeLimit.hour() : '0' + timeLimit.hour()}:${timeLimit.minute() > 9 ? timeLimit.minute() : '0' + timeLimit.minute()}:${timeLimit.second() > 9 ? timeLimit.second() : '0' + timeLimit.second()}`,
			quizData: {
				quizTitle: quizTitle.trim(),
				quizSynopsis: quizSynopsis.trim(),
				questions: questions.map(((question, i) => ({
					id: i,
					question: question.statement.trim(),
					answerSelectionType: (question.type === "MCQ") ? ("multiple") : (
						(question.type === "TF") ? 	("single") : (
						(question.type === "SAT") ? ("short answer type") : (
													("fill in the blanks")
						)) 
					),
					answers: question.options, 
					correctAnswer: question.correctAnswers,
					point: question.points,
					explanation: question.explanation.trim()
				}))),
			}
		}

        const res = await axios.patch(
            `${process.env.REACT_APP_BASE_URL}/quiz/${quizId}`,
            quiz,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
        );

		if (res.status === 200) {
			getQuizzes();
			setOpen(false);
		}
	}

	const handleClose = () => {
		setCurrentQuestion({
			type: "MCQ",
			statement: "",
			questionImages: [],
			options: [{
				id: 1,
				optionStatement: 'Option'
			}],
			correctAnswers: [],
			explanation: "",
			points: "1"
		})
		setShowAddQuestionForm(false);
		setOpen(false);
	}

	const handleDragStart = (e, item) => {
        setDraggingQuestion(item);
        e.dataTransfer.setData('text/plain', '');
    };

    const handleDragEnd = () => {
        setDraggingQuestion(null);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
    };

    const handleDrop = (e, targetItem) => {
        if (!draggingQuestion) return;

        const currentIndex = questions.indexOf(draggingQuestion);
        const targetIndex = questions.indexOf(targetItem);

        if (currentIndex !== -1 && targetIndex !== -1) {
            
			setQuestions((prevQuestions) => {
				const newQuestions = prevQuestions.filter((prevQuestion, i) => (i != currentIndex));
				newQuestions.splice(targetIndex, 0, draggingQuestion);
				return newQuestions;
			});
        }
    };

	const theme = createTheme({
		components: {
			MuiFormLabel: {
				styleOverrides: {
					asterisk: { color: "#d32f2f" },
				},
			},
		},
	
	})

  	const handleAddQuestion = () => {
		setQuestions([...questions, currentQuestion])
		setCurrentQuestion({
			type: "MCQ",
			statement: "",
			questionImages: [],
			options: [],
			correctAnswers: [],
			explanation: "",
			points: "1"
		})
		setShowAddQuestionForm(false);
  	}

      return (
		<div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className={`${styles['modal-container']}`}>
                    <div className={`${styles['row']}`}>
                        <h1 className={`${styles['modal-heading']}`}>Edit Quiz</h1>
                        <ClearIcon 
                            onClick={handleClose}
                            sx={{
                                fontSize: "2.5em",
                                cursor: "pointer"
                            }}
                        />
                    </div>
					<div className={`${styles['modal-input-container']}`}>
						<div className={`${styles['row']} ${styles['to-col']}`} style={{gap: "1em", alignItems: "flex-start"}}>
							<ThemeProvider theme={theme}>
								<TextField
									autoFocus={true}
									id="quiz-title"
									label="Quiz Title"
									onBlur={() => {
										setQuizTitle(quizTitle)
										if (quizTitle === '') {
											setIsValidTitle(false);
										}
									}}
									onChange={(e) => {setIsValidTitle(true); setQuizTitle(e.target.value);}}
									value={quizTitle}
									fullWidth
									required
									color={!isValidTitle ? 'error' : 'primary'}
									error={!isValidTitle}
									helperText={!isValidTitle ? '* Quiz title is required' : ''}
								/>
							</ThemeProvider>

							<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-gb'>
								<ThemeProvider theme={theme}>	
									<TimePicker
										label="Time Limit"
										required
										id='quiz-time-limit'
										defaultValue={dayjs('2022-04-17T01:00')}
										value={timeLimit}
										views={['hours', 'minutes', 'seconds']}
										onChange={(newTimeLimit) => {
											setTimeLimit(newTimeLimit)
										}}
										ampm={false}
										color={!isValidTimeLimit ? 'error' : 'primary'}
										error={!isValidTimeLimit}
										helperText={!isValidTimeLimit ? 'Quiz title is required' : ''}
										minTime={dayjs('2022-04-17T00:00:01')}
									/>
								</ThemeProvider>
							</LocalizationProvider>
						</div>

						<TextField
							fullWidth
							id="quiz-synopsis"
							label="Quiz Synopsis"
							onBlur={() => setQuizSynopsis(quizSynopsis)}
							onChange={(e) => setQuizSynopsis(e.target.value)}
							value={quizSynopsis}
							multiline
							rows={3}
						/>

						<div
							className={`${styles['row']}`}
						>
							<div>
								<h3 className={`${styles['h3']}`}>QUESTIONS</h3>
								{
									!isValidQuestions && 
									<p 
										className={styles['error-helper-text']}
									>
										* At least one question is required
									</p>
								}
							</div>
							<div className={`${styles['gapped-flex']}`}>
								<Fab aria-label="add" color={isValidQuestions ? "primary" : 'error'} onClick={() => {setIsValidQuestions(true); setShowAddQuestionForm(false); setShowGenerateQuestionForm(true);}}>
									<Tooltip title="Generate Questions Using AI">
										<AutoAwesomeIcon />
									</Tooltip>
								</Fab>
								<Fab aria-label="add" color={isValidQuestions ? "primary" : 'error'} onClick={() => {setIsValidQuestions(true); setShowAddQuestionForm(true); setShowGenerateQuestionForm(false);}}>
									<Tooltip title="Add Question">
										<AddIcon />
									</Tooltip>
								</Fab>
							</div>
						</div>

						{
							showAddQuestionForm && 
							<div className={`${styles['create-question-form']}`}>
								<div className={`${styles['row']}`}>
									<h3 className={`${styles['h3']}`}>New Question</h3>
									<ClearIcon 
										onClick={(e)=>{
											e.stopPropagation()
											setCurrentQuestion({
												type: "MCQ",
												statement: "",
												questionImages: [],
												options: [{
													id: 1,
													optionStatement: 'Option'
												}],
												correctAnswers: [],
												explanation: "",
												points: "1"
											})
											setShowAddQuestionForm(false)
										}}
										sx={{
											fontSize: "2.5em",
											cursor: "pointer"
										}}
									/>
								</div>
								<NewQuestion 
									currentQuestion={currentQuestion}
									setCurrentQuestion={setCurrentQuestion} 
									addQuestion={handleAddQuestion}
									isNew={true}
								/>
							</div>
						}

						{
							showGenerateQuestionForm && 
							<div className={`${styles['create-question-form']}`}>
								<div className={`${styles['row']}`}>
									<h3 className={`${styles['h3']}`}>Generate Questions</h3>
									<ClearIcon 
										onClick={(e)=>{
											e.stopPropagation()
											setCurrentQuestion({
												type: "MCQ",
												statement: "",
												questionImages: [],
												options: [{
													id: 1,
													optionStatement: 'Option'
												}],
												correctAnswers: [],
												explanation: "",
												points: "1"
											})
											setShowGenerateQuestionForm(false)
										}}
										sx={{
											fontSize: "2.5em",
											cursor: "pointer"
										}}
									/>
								</div>

								<div className={`${styles['row']} ${styles['to-col']}`} style={{gap: "1em", alignItems: "flex-start"}}>
									<ThemeProvider theme={theme}>
										<TextField
											autoFocus={true}
											id="question-topic"
											label="Question Topic"
											onBlur={() => {
												if (topic === '') {
													setIsValidTopic(false);
												}
											}}
											onChange={(e) => {setIsValidTopic(true); setTopic(e.target.value);}}
											value={topic}
											fullWidth
											required
											color={!isValidTopic ? 'error' : 'primary'}
											error={!isValidTopic}
											helperText={!isValidTopic ? '* Question topic is required' : ''}
										/>
									</ThemeProvider>
									<ThemeProvider theme={theme}>
										<TextField
											id="number-of-ques"
											label="Number of Questions"
											onChange={(e) => {setIsValidNoOfQues(true);
												if (e.target.value < types.length){
													return;
												}
												setNoOfQues(e.target.value);}}
											value={noOfQues}
											required
											type='number'
										/>
									</ThemeProvider>
								</div>
								
								<FormLabel>Select question types</FormLabel>
								<div className={styles['question-options']}>
									<FormControlLabel
										value="MCQ"
										control={<Checkbox />}
										label="Multiple Choice Question"
										labelPlacement="end"
										checked={types.includes("MCQ")}
										onChange={(e) => {
											if (e.target.checked) {
												if (!types.includes("MCQ"))	
													setTypes(prevTypes => [...prevTypes, "MCQ"]);
											} else {
												setTypes(prevTypes => prevTypes.filter(type => type !== "MCQ"));
											}
										}}
									/>
									<FormControlLabel
										value="TF"
										control={<Checkbox />}
										label="True or False"
										labelPlacement="end"
										checked={types.includes("TF")}
										onChange={(e) => {
											if (e.target.checked) {
												if (!types.includes("TF"))	
													setTypes(prevTypes => [...prevTypes, "TF"]);
											} else {
												setTypes(prevTypes => prevTypes.filter(type => type !== "TF"));
											}
										}}
									/>
									<FormControlLabel
										value="FITB"
										control={<Checkbox />}
										label="Fill in the Blanks"
										labelPlacement="end"
										checked={types.includes("FITB")}
										onChange={(e) => {
											if (e.target.checked) {
												if (!types.includes("FITB"))	
													setTypes(prevTypes => [...prevTypes, "FITB"]);
											} else {
												setTypes(prevTypes => prevTypes.filter(type => type !== "FITB"));
											}
										}}
									/>
									<FormControlLabel
										value="SAT"
										control={<Checkbox />}
										label="Short Answer"
										labelPlacement="end"
										checked={types.includes("SAT")}
										onChange={(e) => {
											if (e.target.checked) {
												if (!types.includes("SAT"))	
													setTypes(prevTypes => [...prevTypes, "SAT"]);
											} else {
												setTypes(prevTypes => prevTypes.filter(type => type !== "SAT"));
											}
										}}
									/>
								</div>

								<FormLabel>Select difficulty</FormLabel>
								<div
									style={{
										width: "100%",
										padding: "0 2rem"
									}}
								>
									<Slider 
										defaultValue={2} 
										step={1} 
										marks = {[
											{value: 0, label: "Easy"},
											{value: 1, label: "Medium"},
											{value: 2, label: "Hard"}
										]} 
										min={0} 
										max={2}
										onChange={(e) => {
											setDifficulty(e.target.value);
										}} 
										value={difficulty}
										color={difficulty === 0 ? 'success' : difficulty === 1 ? 'warning' : 'error'}
									/>
								</div>

								<Button
									variant='contained'
									className={`btn btn-primary ${styles['add-to-course-btn']}`}
									onClick={async() => {
										setIsGeneratingQuestions(true);
										let diff

										if (difficulty === 0){
											diff = 'easy'
										} else if (difficulty === 1){
											diff = 'intermediate'
										} else if (difficulty === 2){
											diff = 'advanced'
										} else {
											diff = 'easy'
										}

										const res = await axios({
											method: 'post',
											url: `${process.env.REACT_APP_BASE_URL}/quiz/generate-quiz`,
											data: {topic: topic, count: noOfQues, difficulty: diff, courseName: courseName, quizTitle: quizTitle, quizSynopsis: quizSynopsis,typeOfQuestions: types},
											headers: { 'Content-Type': 'application/json' },
										})
										
										const generatedQuestionsRes = res.data

										// const generatedQuestionsRes = [{"questionType":"text","answerSelectionType":"multiple","question":"<p>What does C in acid stand for?</p>","answers":[{"id":1,"optionStatement":"Consistency"},{"id":2,"optionStatement":"Copy"},{"id":3,"optionStatement":"Cancel"},{"id":4,"optionStatement":"Concurrent"}],"correctAnswer":[{"id":1,"correctAnswers":[]}],"point":"1","explanation":"Google ACID"}]
										const generatedQuestions = generatedQuestionsRes.data.map((question, i) => ({
											id: i,
											type: (question.answerSelectionType === "TF") ? "TF" : 
												(question.answerSelectionType === "MCQ") ? "MCQ" : 
												(question.answerSelectionType === "FITB") ? "FITB" : "SAT",
											statement: question.question,
											questionImages: [],
											options: (question.answers.map((answer) => ({
												...answer,
												optionImages: []
											}))),
											correctAnswers: question.correctAnswer,
											explanation: question.explanation || '',
											points: question.point
										}))
										setQuestions((prevQuestions) => (
											[...prevQuestions, ...generatedQuestions]
										))
										setIsGeneratingQuestions(false);
										setShowGenerateQuestionForm(false);
									}}
								> 
									{ 
										isGeneratingQuestions ? (
											<>
												{"Generating... "}
												<CircularProgress size={"25px"} color="inherit" />
											</>
										) : (
											"Generate"
										) 
									}
								</Button>
							</div>
						}

						<div className={`${styles['questions-list']}`}>
							{questions.length === 0 ? (
								<div
									className={`${styles['row-center']}`} 
								>
									<h5>No questions added</h5>
								</div>
							) : (
								questions.map((question, i) => (editQuestionIndex === i) ? (
								<div className={`${styles['create-question-form']}`}>
									<div className={`${styles['row']}`}>
										<h3 className={`${styles['h3']}`}>Edit Question {i + 1}</h3>
										<ClearIcon 
											onClick={()=>{
												setEditQuestionIndex(-1)
											}}
											sx={{
												fontSize: "2.5em",
												cursor: "pointer"
											}}
										/>
									</div>
									<NewQuestion 
										currentQuestion={questions[editQuestionIndex]}
										setCurrentQuestion={(fn) => {
											setQuestions((prevQuestions) => (
												prevQuestions.map((prevQuestion, j) => {
													if (i === j) {
														return fn(prevQuestion);
													} else {
														return prevQuestion;
													}
												})
											))
										}} 
										addQuestion={() => setEditQuestionIndex(-1)}
										isNew={false}
									/>
								</div>
							) : (
								<div 
									draggable="true" 
									onDragStart={(e) => handleDragStart(e, question)}
									onDragEnd={handleDragEnd}
									onDragOver={handleDragOver}
									onDrop={(e) => handleDrop(e, question)} 
									key={i}
									onClick={() => {
										setEditQuestionIndex(i);
									}}
									className={`${styles['create-question-container']}`}
								>
									<div 
										style={{
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											cursor: "grab"
										}}
									>
										<DragIndicatorIcon
											sx={{
												color: "rgba(0, 0, 0, 0.50)"
											}}
										/>
									</div>
									<div>
										<div className={`${styles['row']}`}>
											<h3 className={`${styles['h3']}`}>Question {i + 1}</h3>
											<div>
												<Tooltip title="Delete This Question">
													<DeleteIcon 
														onClick={
															(e)=>{
																e.preventDefault();
                            									e.stopPropagation();
																setQuestions(questions => (
																	questions.filter((question, j) => (
																		i !== j 
																	))
																))
															}
														} 
														sx={{
															fontSize: "2em"
														}}
													/>
												</Tooltip>
											</div>
										</div>	
										<div dangerouslySetInnerHTML={{__html: question.statement}}></div>
									</div>
								</div>
							)))}
						</div>
					</div>
					<div className={`${styles['row-center']}`} style={{gap: '1em'}}>
                        <Button
                            onClick={() => handleSubmit()}
                            variant='contained'
                            size='large'
                        >
                            Save
                        </Button>
                    </div>
                </div>
            </Modal>
        </div>
	)
}

export default EditQuiz