/*eslint-disable*/
import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { useSelector} from 'react-redux'
import PropTypes from 'prop-types'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import {
    InputAdornment,
    MenuItem,
    Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material'
import DeleteIcon from '@mui/icons-material/Delete'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline'
import {
    tableFooterCellTheme,
    tableFooterTheme,
    tableHeaderCellSecondaryTheme,
    tableHeaderRowSecondaryTheme,
} from './Theme'
import {
    CATEGORY_WEIGHT_DECIMAL_ACCURACY,
    POINTS_DECIMAL_ACCURACY,
} from '../../utils/constants'
import CategoryItemRow from './CategoryItemRow'
import '../../css/FAIcons.css'
import {
    getRemainingMaxPointsOfHybridCategory,
    getRemainingWeightOfHybridCategory,
} from '../../redux/course/helpers/hybridCategoryHelper'
import { getRemainingMaxPointsOfPointsBasedCategory } from '../../redux/course/helpers/pointsBasedCategoryHelper'

export default function CategoryRow({
    category,
    maximumPoints,
    weight,
    itemWeights,
    onCategoryNameChange,
    onCategoryTypeChange,
    onCategoryMaxPointsChange,
    onCategoryWeightChange,
    onItemNameChange,
    onItemDescriptionChange,
    onItemKnownScoreChange,
    onItemGradeChange,
    onItemMaximumPointChange,
    onItemWeightChange,
    enableModifyGrade,
    deleteCategory,
    deleteItem,
    addItem,
    errorData,
    achievingTargetGrade,
    gradesInAchievingTargetGrade,
}) {
    const [width, setWidth] = useState(window.innerWidth)
    const [tooltipIsOpen, setTooltipIsOpen] = useState(false)
    const [open, setOpen] = React.useState(false)
    const userData = useSelector((state) => state.userData)
    const currentCourseData = useSelector((state) => state.currentCourseData)
    const [isInstructorOfCourse, setIsInstructorOfCourse] = useState(false)

    const isHybridCategory = category.categoryType === 'HYBRID'
    const isPointsBasedCategory = category.categoryType === 'POINTS_BASED'
    const isStandardCategory = category.categoryType === 'STANDARD'

    const editableMaxPoints = isHybridCategory || isPointsBasedCategory
    const editableWeight = isHybridCategory
    const editableItemWeights = isStandardCategory

    let remainingMaxPoints = 0
    let remainingWeights = 0

    const { id: courseId } = useParams()
    const [creatorId, setCreatorId] = useState('')

    useEffect(() => {
        async function fetchData() {
            try {
                const coursedetails = await axios.get(
                    `${process.env.REACT_APP_BASE_URL}/api/v2/course/${courseId}`
                );
                setCreatorId(coursedetails.data.createdBy);
            } catch (err) {
                console.error('Error fetching details:', err);
            }
        }

        fetchData();
    }, [courseId]);

    useEffect(() => {
        setIsInstructorOfCourse((userData.user.id === currentCourseData.createdBy) || (userData.user.id === creatorId))
        // console.log('Instrcutor: ')
        // console.log(isInstructorOfCourse)
        // console.log(creatorId, courseId, userData.user.id)
    }, [currentCourseData.createdBy, userData.user.id, creatorId])

    if (isHybridCategory) {
        remainingMaxPoints = getRemainingMaxPointsOfHybridCategory(category)
        remainingWeights = getRemainingWeightOfHybridCategory(category)
    }

    if (isPointsBasedCategory) {
        remainingMaxPoints =
            getRemainingMaxPointsOfPointsBasedCategory(category)
    }

    function handleWindowSizeChange() {
        setWidth(window.innerWidth)
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange)
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange)
        }
    }, [])
    const isMobileOrTab = width <= 1200

    const handleDeleteCategoryClicked = () => {
        if (
            // eslint-disable-next-line no-alert
            window.confirm(
                "Are you sure you want to delete this category? This'll also delete all items in this category."
            )
        ) {
            deleteCategory()
        }
    }

    const handleDeleteItemClicked = (itemIdx) => {
        // eslint-disable-next-line no-alert
        if (window.confirm('Are you sure you want to delete this item?')) {
            deleteItem(itemIdx)
        }
    }
    useEffect(() => {
        if (achievingTargetGrade) {
            setOpen(achievingTargetGrade)
        }
    }, [achievingTargetGrade])
    return (
        <>
            <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell className={`${isMobileOrTab ? 'p-0' : ''}`}>
                    <IconButton
                        aria-label="expand row"
                        onClick={() => setOpen(!open)}
                        size="small"
                    >
                        {open ? (
                            <KeyboardArrowUpIcon />
                        ) : (
                            <KeyboardArrowDownIcon />
                        )}
                    </IconButton>
                </TableCell>
                <TableCell component="th" scope="row">
                    <TextField
                        disabled={!isInstructorOfCourse}
                        error={errorData.name}
                        helperText={errorData.name}
                        inputProps={{
                            size: 'small',
                        }}
                        onChange={(e) => onCategoryNameChange(e.target.value)}
                        value={category.name}
                        variant="standard"
                    />
                </TableCell>
                <TableCell component="th" scope="row">
                    <Select
                        fullWidth
                        disabled={!isInstructorOfCourse}
                        onChange={(e) => onCategoryTypeChange(e.target.value)}
                        value={category.categoryType}
                        variant="standard"
                    >
                        <MenuItem value="STANDARD">Weight Based</MenuItem>
                        <MenuItem value="POINTS_BASED">Points Based</MenuItem>
                        <MenuItem value="HYBRID">Hybrid</MenuItem>
                    </Select>
                </TableCell>
                <TableCell align="center" component="th" scope="row">
                    <TextField
                        disabled={!editableMaxPoints}
                        error={errorData.maximumPoints}
                        helperText={errorData.maximumPoints}
                        inputProps={{
                            type: 'number',
                            min: 0,
                            max: 10000,
                            size: 'small',
                            step: 0.1 ** POINTS_DECIMAL_ACCURACY,
                            style: { textAlign: 'center' },
                        }}
                        onChange={(e) =>
                            onCategoryMaxPointsChange(e.target.value)
                        }
                        onWheel={(e) => e.target.blur()}
                        value={maximumPoints.toString()}
                        variant="standard"
                    />
                </TableCell>
                <TableCell align="right">
                    <TextField
                        disabled={!editableWeight}
                        error={errorData.weight}
                        helperText={errorData.weight}
                        inputProps={{
                            type: 'number',
                            size: 'small',
                            min: 0,
                            max: 100,
                            step: 0.1 ** CATEGORY_WEIGHT_DECIMAL_ACCURACY,
                            style: { textAlign: 'center', minWidth: '50px' },
                        }}
                        // eslint-disable-next-line react/jsx-no-duplicate-props
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="start">
                                    %
                                </InputAdornment>
                            ),
                        }}
                        onChange={(e) => onCategoryWeightChange(e.target.value)}
                        onWheel={(e) => e.target.blur()}
                        value={weight.toString()}
                        variant="standard"
                    />
                </TableCell>
                <TableCell align="center">
                    <IconButton
                        aria-label="delete category"
                        disabled={!enableModifyGrade || !isInstructorOfCourse}
                        edge="end"
                        onClick={() => handleDeleteCategoryClicked()}
                    >
                        <DeleteIcon />
                    </IconButton>
                </TableCell>
            </TableRow>
            <TableRow>
                <TableCell
                    colSpan={6}
                    style={{ paddingBottom: 0, paddingTop: 0 }}
                >
                    <Collapse in={open} timeout="auto" unmountOnExit>
                        <Box sx={{ margin: 0.5 }}>
                            <Table aria-label="category items" size="small">
                                <colgroup>
                                    <col style={{ width: '25%' }} />
                                    <col style={{ width: '40%' }} />
                                    <col style={{ width: '15%' }} />
                                    <col style={{ width: '15%' }} />
                                    <col style={{ width: '5%' }} />
                                </colgroup>
                                <TableHead>
                                    <TableRow sx={tableHeaderRowSecondaryTheme}>
                                        <TableCell
                                            align="center"
                                            sx={tableHeaderCellSecondaryTheme}
                                        >
                                            Name
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={tableHeaderCellSecondaryTheme}
                                        >
                                            Points
                                            <Tooltip
                                                className="ml-1"
                                                onClick={() =>
                                                    setTooltipIsOpen(true)
                                                }
                                                onMouseOut={() =>
                                                    setTooltipIsOpen(false)
                                                }
                                                onOpen={() =>
                                                    setTooltipIsOpen(true)
                                                }
                                                open={tooltipIsOpen}
                                                sx={{ paddingBottom: '2px' }}
                                                title="You can enter points more than Max Points as bonus points for each item"
                                            >
                                                <InfoOutlinedIcon fontSize="small" />
                                            </Tooltip>
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={tableHeaderCellSecondaryTheme}
                                        >
                                            Max Points
                                        </TableCell>
                                        <TableCell
                                            align="center"
                                            sx={tableHeaderCellSecondaryTheme}
                                        >
                                            Weight
                                        </TableCell>
                                        <TableCell />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {category.items.map((item, idx) => (
                                        <CategoryItemRow
                                            // eslint-disable-next-line react/no-array-index-key
                                            key={idx}
                                            categoryType={category.categoryType}
                                            categoryWeight={weight}
                                            description={item.description}
                                            editableWeight={editableItemWeights}
                                            enableModifyGrade={
                                                enableModifyGrade
                                            }
                                            errorData={
                                                errorData.items
                                                    ? errorData.items[idx]
                                                    : {}
                                            }
                                            grade={
                                                achievingTargetGrade &&
                                                !item.knownScores
                                                    ? gradesInAchievingTargetGrade[
                                                          idx
                                                      ]
                                                    : item.grade
                                            }
                                            handleDeleteItemClicked={() =>
                                                handleDeleteItemClicked(idx)
                                            }
                                            knownScore={item.knownScores}
                                            maximumPoints={item.maximumPoints}
                                            name={item.name}
                                            onItemDescriptionChange={(value) =>
                                                onItemDescriptionChange(
                                                    idx,
                                                    value
                                                )
                                            }
                                            onItemGradeChange={(value) =>
                                                onItemGradeChange(idx, value)
                                            }
                                            onItemKnownScoreChange={(value) =>
                                                onItemKnownScoreChange(
                                                    idx,
                                                    value
                                                )
                                            }
                                            onItemMaximumPointChange={(value) =>
                                                onItemMaximumPointChange(
                                                    idx,
                                                    value
                                                )
                                            }
                                            onItemNameChange={(value) =>
                                                onItemNameChange(idx, value)
                                            }
                                            onItemWeightChange={(value) =>
                                                onItemWeightChange(idx, value)
                                            }
                                            weight={itemWeights[idx]}
                                        />
                                    ))}
                                    <TableRow sx={tableFooterTheme}>
                                        <TableCell
                                            colSpan={isStandardCategory ? 5 : 1}
                                            size="small"
                                        >
                                            <IconButton
                                                aria-label="add an item"
                                                disabled={!enableModifyGrade}
                                                edge="end"
                                                onClick={() => addItem()}
                                                size="small"
                                            >
                                                <AddCircleOutlineIcon />
                                                <Typography marginLeft="12px">
                                                    Add an item
                                                </Typography>
                                            </IconButton>
                                        </TableCell>
                                        {isHybridCategory ||
                                        isPointsBasedCategory ? (
                                            <>
                                                <TableCell
                                                    colSpan={2}
                                                    size="small"
                                                    sx={tableFooterCellTheme}
                                                >
                                                    <Typography align="right">
                                                        Remaining Max Points:{' '}
                                                        <b>
                                                            {remainingMaxPoints}
                                                        </b>
                                                    </Typography>
                                                </TableCell>
                                                <TableCell
                                                    colSpan={2}
                                                    size="small"
                                                    sx={tableFooterCellTheme}
                                                >
                                                    {isHybridCategory ? (
                                                        <Typography>
                                                            Remaining Weight:{' '}
                                                            <b>
                                                                {
                                                                    remainingWeights
                                                                }
                                                            </b>
                                                        </Typography>
                                                    ) : null}
                                                </TableCell>
                                            </>
                                        ) : null}
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    )
}

CategoryRow.propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    category: PropTypes.object,
    maximumPoints: PropTypes.number,
    weight: PropTypes.number,
    itemWeights: PropTypes.arrayOf(PropTypes.number),
    onCategoryNameChange: PropTypes.func.isRequired,
    onCategoryTypeChange: PropTypes.func.isRequired,
    onCategoryMaxPointsChange: PropTypes.func.isRequired,
    onCategoryWeightChange: PropTypes.func.isRequired,
    onItemNameChange: PropTypes.func.isRequired,
    onItemDescriptionChange: PropTypes.func.isRequired,
    onItemKnownScoreChange: PropTypes.func.isRequired,
    onItemGradeChange: PropTypes.func.isRequired,
    onItemMaximumPointChange: PropTypes.func.isRequired,
    onItemWeightChange: PropTypes.func.isRequired,
    enableModifyGrade: PropTypes.bool,
    deleteCategory: PropTypes.func.isRequired,
    deleteItem: PropTypes.func.isRequired,
    addItem: PropTypes.func.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    errorData: PropTypes.object,
    achievingTargetGrade: PropTypes.bool,
    gradesInAchievingTargetGrade: PropTypes.arrayOf(PropTypes.number),
}

CategoryRow.defaultProps = {
    category: [],
    maximumPoints: 0,
    weight: 0,
    itemWeights: [],
    enableModifyGrade: true,
    errorData: {},
    achievingTargetGrade: false,
    gradesInAchievingTargetGrade: [],
}
