/*eslint-disable*/
import React, { useRef, useState } from 'react';
import styles from './AnnouncementThumbnail.module.css'
import { ClickAwayListener, Divider, Grow, Menu, MenuItem, MenuList, Paper, Popper } from '@mui/material';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import dayjs from 'dayjs';
import axios from 'axios';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import EditAnnouncement from './EditAnnouncement';

const AnnouncementThumbnail = ({announcement, getAnnouncements, userRole}) => {
    const {id: courseId} = useParams();
    const anchorRef = useRef(null);
    const [isOpenMenu, setIsOpenMenu] = useState(false);
    const [isEditAnnouncementOpen, setIsEditAnnouncementOpen] = useState(false);
    const date = dayjs(announcement.createdTime);
    const monthDictionary = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

    const displayMenu = (e) => {
        setIsOpenMenu(true);
    }

    const closeMenu = () => {
        setIsOpenMenu(false);
    }

    const handleListKeyDown = (event) => {
        if (event.key === 'Tab') {
            event.preventDefault();
            setIsOpenMenu(false);
        } else if (event.key === 'Escape') {
            setIsOpenMenu(false);
        }
    }

    const handleDeleteAnnouncement = async () => {
        try {
            const res = await axios({
				method: 'delete',
				url: `${process.env.REACT_APP_BASE_URL}/api/v2/course/${courseId}/announcement/${announcement.id}`,
				headers: { 'Content-Type': 'application/json' }
			})
            getAnnouncements();
        } catch(error) {
            console.log(error);
        }
    }

    const handleEditAnnouncement = async (title, body) => {        
        try {
            const res = await axios({
                method: 'patch',
                url: `${process.env.REACT_APP_BASE_URL}/api/v2/course/${courseId}/announcement/${announcement.id}`,
                data: {
                    title: title,
                    body: body,
                    createdTime: announcement.createdTime
                },
                headers: { 'Content-Type': 'application/json' }
            })
            getAnnouncements();
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <div className={styles['announcement-tile']}>
            <div className={styles['announcement-date-container']}>
                <div className={styles['announcement-time']}>{`${date.format("HH:mm")}`}</div>
                <div className={styles['announcement-date']}>
                    <div className={styles['announcement-date-month']}>{`${date.date()} ${monthDictionary[date.month()]}`}</div>
                    <div className={styles['announcement-year']}>{date.year()}</div>
                </div>
            </div>
            <div className={styles['announcement-main-container']}>
                <div className={styles['announcement-top-container']}>
                    <div className={styles['announcement-title']}>
                        {announcement.title}
                    </div>
                    <div className={styles['announcement-creator-name']}>by {announcement.creatorName}</div>
                </div>
                <Divider />
                <div className={styles['announcement-description']}>{announcement.body}</div>
            </div>
            {
                (userRole === "Instructor" || userRole === "TA") &&
                <MoreHorizIcon ref={anchorRef} className={styles['announcement-more-menu']} onClick={displayMenu} />
            }
            <Popper
                open={isOpenMenu}
                anchorEl={anchorRef.current}
                role={undefined}
                placement="bottom-start"
                transition
                disablePortal
            >
                {({ TransitionProps, placement }) => (
                    <Grow
                        {...TransitionProps}
                        style={{
                            transformOrigin: 'right top',
                        }}
                    >
                    <Paper>
                        <ClickAwayListener onClickAway={closeMenu}>
                        <MenuList
                            autoFocusItem={open}
                            id="composition-menu"
                            aria-labelledby="composition-button"
                            onKeyDown={handleListKeyDown}
                        >
                            <MenuItem style={{display: "flex", gap: "0.5rem", alignItems: "center"}} onClick={()=>{setIsEditAnnouncementOpen(true)}}><EditIcon /> Edit</MenuItem>
                            <MenuItem style={{display: "flex", gap: "0.5rem", alignItems: "center"}} onClick={handleDeleteAnnouncement}><DeleteIcon /> Delete</MenuItem>
                        </MenuList>
                        </ClickAwayListener>
                    </Paper>
                    </Grow>
                )}
            </Popper>
            <EditAnnouncement announcement={announcement} open={isEditAnnouncementOpen} setOpen={setIsEditAnnouncementOpen} editAnnouncement={handleEditAnnouncement} />
        </div>
    )
}

export default AnnouncementThumbnail