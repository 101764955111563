/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { Divider, Fab, Skeleton, Tab, Tabs, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import tabStyles from '../Tab.module.css';
import styles from './Announcements.module.css';
import AnnouncementThumbnail from './AnnouncementThumbnail';
import axios from 'axios';
import CreateAnnouncement from './CreateAnnouncement';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';

const Announcements = ({isLoading, role}) => {
	const {id: courseId} = useParams();
	const [isCreateAnnouncementModalOpen, setIsCreateAnnouncementModalOpen] = useState(false);
	const [isAnnouncementsLoading, setIsAnnouncementsLoading] = useState(true);
	const [announcements, setAnnouncements] = useState([{}, {}, {}]);

	const createAnnouncement = async (title, body, createdTimeStr) => {
		try {
			const res = await axios({
				method: 'post',
				url: `${process.env.REACT_APP_BASE_URL}/api/v2/course/${courseId}/announcements`,
				data: {
					title: title,
					body: body,
					createdTime: createdTimeStr
				},
				headers: { 'Content-Type': 'application/json' }
			})
			getAnnouncements();
		} catch (error) {
			console.log(error)
		}
	}

	const getAnnouncements = async () => {
		try {
			const res = await axios({
				method: 'get',
				url: `${process.env.REACT_APP_BASE_URL}/api/v2/course/${courseId}/announcements`,
				headers: { 'Content-Type': 'application/json' }
			})
			setAnnouncements(res.data);
			setIsAnnouncementsLoading(false);
		} catch(error) {
			console.log('ERROR: ', error);
		}			 
	}

	useEffect(() => {
		if (role !== "") {
			getAnnouncements();
		}
	}, [role])

	return (
		<div>
			<div className={`${tabStyles['tab-heading']}`}>
				Announcements
				{
					(role === "Instructor" || role === "TA") && (
						<Fab aria-label="add" color="primary" disabled={isLoading} onClick={()=>setIsCreateAnnouncementModalOpen(true)}>
							<Tooltip title="Create Announcement">
								<AddIcon />
							</Tooltip>
						</Fab>
					)
				}
			</div>
			<Divider />
			<div className={`${styles['announcements-list']}`}>
				{
					announcements?.map((announcement) => (
						isAnnouncementsLoading ? (
							<Skeleton height={"100px"} width="100%" variant='rectangle' />
						) : (
							<AnnouncementThumbnail userRole={role} getAnnouncements={getAnnouncements} announcement={announcement} />
						)
					))
				}
			</div>
            <CreateAnnouncement
				open={isCreateAnnouncementModalOpen}
				setOpen={setIsCreateAnnouncementModalOpen}
				createAnnouncement={createAnnouncement} 
			/>
		</div>
	)
}

export default Announcements