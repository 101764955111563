/* eslint-disable */
import { Button, Modal } from '@mui/material'
import React, { useEffect } from 'react'
import ClearIcon from '@mui/icons-material/Clear';
import modalStyles from '../../css/Modal.module.css';

const DeleteCourseModal = ({open, courseTitle, handleClose, onDelete}) => {
    const handleConfirmDelete = () => {
        onDelete();
    }
    
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className={`${modalStyles['container']}`}>
                <div className={`${modalStyles['top-container']}`}>
                    <h1 className={`${modalStyles['heading']}`}>Delete Course</h1>
                    <ClearIcon 
                        onClick={handleClose}
                        sx={{
                            fontSize: "2.5em",
                            cursor: "pointer"
                        }}
                    />
                </div>
                <div className={`${modalStyles['main-container']}`}>
                    Are you sure you want to delete <b>{courseTitle}</b>?
                </div>
                <div className={`${modalStyles['button-container']}`}>
                    <Button
                        className={`btn btn-primary ${modalStyles['add-to-course-btn']}`}
                        onClick={handleConfirmDelete}
                        variant='contained'
                        size='large'
                    >
                        Delete
                    </Button>
                </div>
            </div>
        </Modal>
    )
}

export default DeleteCourseModal