/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { Button, Divider, Fab, Skeleton, Tab, Tabs, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import tabStyles from '../Tab.module.css';
import styles from './Syllabus.module.css';
import RTE from '../../../commons/RTE';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import FileDisplayModal from './FileDisplayModal';
import { Document } from 'react-pdf';
import { Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import { Delete } from '@mui/icons-material';
import Files from '../../../commons/Files';
import axios from 'axios';
import UploadFileModal from '../../../commons/UploadFileModal';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const Syllabus = ({isLoading, role}) => {
	const [isEditing, setIsEditing] = useState(false);
	const [syllabusText, setSyllabusText] = useState("");
	const [isUploadFileModalOpen, setIsUploadFileModalOpen] = useState(false);
	const [files, setFiles] = useState([]);
	const [isFileOpen, setIsFileOpen] = useState(false);
	const [selectedFile, setSelectedFile] = useState(null);
	const [isDeleteFileModalOpen, setIsDeleteFileModalOpen] = useState(false);
	const [fileToBeDeleted, setFileToBeDeleted] = useState(false);
    const { id: courseId } = useParams()

	const fileIcons = {
		'pdf': '/icons/pdf_icon.svg',
		'doc': '/icons/doc_icon.svg',
		'docx': '/icons/doc_icon.svg',
	}

	const closeFileModal = () => {
		setIsUploadFileModalOpen(false);
	}

	const accept = '.pdf, .docx, .doc, .pptx, .xlsx, .txt'

    const getFiles = async () => {
		
		try {
			const response = await axios.get(`${process.env.REACT_APP_BASE_URL}/getFilesFromS3/${courseId}`);
			const receivedFiles = response.data.files;
			setFiles(receivedFiles); // Assuming you have a state to hold files
		} catch (error) {
			console.error('Error listing S3 objects: ', error);
		}
	};
	

	const getFileExtension = (file) => {
		const splitName = getFileName(file).split('.');
		return splitName[splitName.length - 1];
	}

	const getFileName = (fileUrl) => {
		const splitUrl = fileUrl.split('/');
		return splitUrl[splitUrl.length - 1];
	}

	const closeFile = () => {
		setIsFileOpen(false);
	}

	const [numPages, setNumPages] = useState();

	function onDocumentLoadSuccess({ numPages: nextNumPages }) {
		setNumPages(nextNumPages);
	}

	const generateGoogleDocsViewerUrl = (s3Url) => {
		return `https://docs.google.com/viewer?url=${encodeURIComponent(s3Url)}&embedded=true`;
	};

	const handleOpenFile = async (file) => {
    try {
        // Make a POST request to get the signed URL from the backend
        const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/getDownloadUrlFromS3`, {
            fileKey: file, // Send the file key (file path) to the backend
        });

        const url = response.data.downloadUrl; // Get the signed URL from the response

        const splitUrl = file.split('.');
        const ext = splitUrl[splitUrl.length - 1];

        if (ext === 'doc' || ext === 'docx') {
            setSelectedFile(generateGoogleDocsViewerUrl(url));
        } else if (ext === 'pdf') {
            setSelectedFile(url);
        } else {
            console.log('NOT SUPPORTED');
        }

        setIsFileOpen(true);
    } catch (error) {
        console.error('Error getting download URL:', error);
    }
};


	const closeDeleteFileModal = () => {setIsDeleteFileModalOpen(false)}

	const handleConfirmDelete = async (fileToBeDeleted) => {
		if (!fileToBeDeleted) return;
		
		try {
			// Make a POST request to the backend to delete the file from S3
			await axios.post(`${process.env.REACT_APP_BASE_URL}/deleteFileFromS3`, {
				fileKey: `${courseId}/syllabus/${fileToBeDeleted}`, // Pass the file key (path) to the backend
			});
			
			
			// Refresh file list after deletion
			getFiles();
		} catch (error) {
			console.error('Error deleting file: ', error);
		}
	};
	

	const getSyllabusText = async () => {
		try {
            const res = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/api/v2/course/${courseId}`,
                headers: { 'Content-Type': 'application/json' }
            })
            setSyllabusText(res.data.syllabus);
        } catch(error) {
            console.log('ERROR: ', error);
        }
	}
	
	const handleSaveSyllabusText = async () => {
		try {
			await axios({
				method: 'patch',
				url: `${process.env.REACT_APP_BASE_URL}/api/v2/course/${courseId}/syllabus`,
				data: {
					syllabus: syllabusText
				},
				headers: { 'Content-Type': 'application/json' }
			})
			getSyllabusText();
			setIsEditing(false);
		} catch (error) {
			console.log(error)
		}
	}

	const mimeTypes = {
		pdf: 'application/pdf',
		doc: 'application/msword',
		docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
		png: 'image/png',
		jpg: 'image/jpeg',
		jpeg: 'image/jpeg',
		gif: 'image/gif',
		txt: 'text/plain',
		csv: 'text/csv',
		xls: 'application/vnd.ms-excel',
		xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		zip: 'application/zip',
		pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
		ppt: 'application/vnd.ms-powerpoint'
	};

	const handleFileUpload = async (file) => {
		if (!file) {
			return
        }

        try {
			const fileName = `${courseId}/syllabus/${file.name}`
            const splitFileName = fileName.split('.');
            const ext = splitFileName[splitFileName.length - 1];
			const supportedTypes = ['pdf', 'docx', 'doc', 'ppt', 'pptx', 'xlsx', 'txt'];
            if (!supportedTypes.includes(ext)) {
                alert("file type not supported - syllabus")
                return;
            }

            const blob = new Blob([file], {  type: mimeTypes[ext] });
            const formData = new FormData();
            formData.append("file", blob, file.name); // Provide a file name
            formData.append('courseId', courseId); // Course ID
            formData.append('moduleName', 'syllabus'); // Course ID
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/uploadFileToS3`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });

            // const { s3Url } = response.data;

            // // Create FormData with S3 URL
            // const formData2 = new FormData()
            // formData2.append('fileID', s3Url) // S3 URL as fileID
            // formData2.append('file', fileBlob)
            // formData2.append('course_id', courseId)

            // // Send to /uploadpdf route
            // await axios.post('https://turing.myacademic.org/uploadpdf', formData, {
            //     headers: {
            //         'Content-Type': 'multipart/form-data',
            //     },
            // })
            getFiles();
        } catch (error) {
            console.error('Error uploading file: ', error)
            // alert('File not uploaded!')
        }
    }

	useEffect(()=>{
		if (role !== "") {
			getFiles();
			getSyllabusText();
		}
    }, [role]);

    return (
		<div className={`${tabStyles['tab-container']}`}>
			<div className={`${tabStyles['tab-heading']}`}>
                Syllabus
			</div>
            <Divider />
			<div className={`${styles['container']}`}>
				<div className={`${styles['syllabus-container']}`}>
					<div className={`${styles['container-heading']}`}>
						Description
						{
							(role === "Instructor" || role === "TA") &&
							<Tooltip title="Edit Syllabus">
								<Fab aria-label="add" color="primary" disabled={isEditing} onClick={()=>setIsEditing(true)}>
									<EditIcon disabled={isEditing} />
								</Fab>
							</Tooltip>
						}
					</div>	
					<Divider variant='middle' />
					<div className={styles['main-text-container']}>
						{
							isEditing ? 
							(
								<>
									<RTE value={syllabusText} setValue={setSyllabusText} placeholder={'Enter syllabus'} />
									<Button 
										className={styles['save-btn']} 
										onClick={()=>{
											handleSaveSyllabusText();
										}} 
										variant='contained'
									>
										Save
									</Button>
								</>
							) : (
								<div dangerouslySetInnerHTML={{__html: syllabusText}} />
							)
						}
					</div>
				</div>
				<Divider orientation='vertical' flexItem />
				<div className={`${styles['files-container']}`}>
					<div className={`${styles['container-heading']}`}>
						Files
						{
							(role === "Instructor" || role === "TA") &&
							<Tooltip title="Upload file">
								<Fab aria-label="add" color="primary" disabled={isLoading} onClick={()=>setIsUploadFileModalOpen(true)}>
									<UploadFileIcon disabled={isEditing} />
								</Fab>
							</Tooltip>
						}
					</div>	
					<Divider variant='middle' />
					{/* <div className={styles['files']}>
						{
							files.map((file) => (
								<div 
									className={styles['file']} 
									onClick={() => {
										handleOpenFile(file)
									}}	
								>
									<div className={styles['file-icon-title-container']}>
										<img className={styles['file-icon']} src={fileIcons[getFileExtension(file)]} />
										{getFileName(file).substring(0, 50)}
									</div>
									<Tooltip title='Delete file'>
										<Delete 
											onClick={(e) => {
												console.log(e);
												e.preventDefault();
												e.stopPropagation();
												setIsDeleteFileModalOpen(true);
												setFileToBeDeleted(file)}
											} 
										/>
									</Tooltip>
								</div>
							))
						}
					</div> */}
					<Files 
						deleteFile={handleConfirmDelete} 
						files={files} 
						setFiles={setFiles} 
						getFiles={getFiles} 
						prefix={`${courseId}/syllabus`} 
						role={role}
					/>
				</div>
			</div>
			<UploadFileModal
				open={isUploadFileModalOpen} 
				handleClose={closeFileModal}
				onSubmit={(file) => handleFileUpload(file)} 
				accept={accept}
			/>
			<FileDisplayModal
				open={isFileOpen}
				handleClose={closeFile}
				file={selectedFile}
			/>
		</div>
	)
}

export default Syllabus