/*eslint-disable*/

import React, { Fragment, useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'

import axios from 'axios'
import { useHistory } from 'react-router-dom'
import { AgGridReact } from 'ag-grid-react'
import 'ag-grid-community/styles/ag-grid.css'
import 'ag-grid-community/styles/ag-theme-quartz.css'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import GroupAddIcon from '@mui/icons-material/GroupAdd';
import TextField from '@mui/material/TextField';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import Menu from '@mui/material/Menu'
import UploadFileIcon from '@mui/icons-material/UploadFile';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography'
import ListItemText from '@mui/material/ListItemText';
import * as XLSX from 'xlsx';
import * as Papa from 'papaparse';
import Collapse from '@mui/material/Collapse';
import MenuItem from '@mui/material/MenuItem';
import AddIcon from '@mui/icons-material/Add';
import tabStyles from './Tab.module.css';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Divider, Skeleton, Tab, Tabs, Tooltip } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';


export default function People({ isLoading,courseInfo }) {

  const { id: courseId } = useParams();
  const [isDeleteAlertOpen, setDeleteAlertOpen] = useState(false);
  
  const [curUserEmail, setCurUserEmail] = useState("");

  const [creatorMail,setCreatorMail] = useState('');
  const [usersList, setUsersList] = useState([])
  const [rowData, setRowData] = useState([])

  const [instructorCount, setInstructorCount] = useState(0);

  const [curUserDetails, setCurUserDetails] = useState(null);
  const [reloadFlag, setReloadFlag] = useState(false);

  const [addUserDialogOpen, setAddUserDialogOpen] = useState(false);

  const [emailsToBeAdded, setEmailsToBeAdded] = useState([]);

  const [emailPresenceMap, setEmailPresenceMap] = useState(new Map());

  const [registeredUserEntered, setRegisterUserEntered] = useState(true);

  const [selectedFile, setSelectedFile] = useState(null);

  const [value, setValue] = useState('1');

  const [roleSelectedAdd, setRoleSelectedAdd] = useState('');

  const userData = useSelector((state) => state.userData);

  const [isCurUserInstructor, setisCurUserInstructor] = useState(false);

  const isAdminAccount = userData && userData.user && userData.user.email === process.env.REACT_APP_ADMIN_EMAIL_ADDRESS;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v2/courses/${courseId}/students`
        )

        const creatorEmail = await axios.get(
          `${process.env.REACT_APP_BASE_URL}/api/v2/courses/${courseId}/creator-mail`
        )
        setCreatorMail(creatorEmail.data.mail)
        
        if (response.data) {
          let sortedList = []
          
          for(let person of response.data.people){
            if(person.role==='Instructor'){
              setInstructorCount((prev)=>prev+1);
              sortedList.push(person);
            }
          }
          for(let person of response.data.people){
            if(person.role==='TA'){
              sortedList.push(person);
            }
          }
          for(let person of response.data.people){
            if(person.role==='Student'){
              sortedList.push(person);
            }
          }
          setUsersList(sortedList);
          const curUser = await axios.get(`${process.env.REACT_APP_BASE_URL}/user`);

          setCurUserDetails(curUser.data);
          for(let user of sortedList){
            if(user.email===curUser.data.email){
              setisCurUserInstructor(user.role==='Instructor'); 
              break;
            }
          }
        }

        
        
        

      } catch (err) {
        console.log(err)
      }
    }
    fetchData()
  }, [reloadFlag])

  useEffect(() => {
    if (!usersList || usersList.length === 0) return;
    
    const rows = usersList.map((user) => ({
      Username: `${user.firstName} ${user.lastName}`,
      Email: user.email,
      Role: user.role,
      Actions: [user.role,user.email, isCurUserInstructor]
    }));

    setRowData(rows);
  }, [usersList,isCurUserInstructor]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);

    if (!file) return;

    const fileExtension = file.name.split('.').pop().toLowerCase();

    if (fileExtension === 'csv') {

      Papa.parse(file, {
        complete: (result) => {
          const parsedEmails = result.data.flat().filter(email => email.includes('@'));
          setEmailsToBeAdded(parsedEmails);


          checkEmailPresence(parsedEmails);
        },
        header: false,
      });
    } else if (fileExtension === 'xlsx') {

      const reader = new FileReader();
      reader.onload = (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const firstSheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[firstSheetName];
        const sheetData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

        const parsedEmails = sheetData.flat().filter(email => typeof email === 'string' && email.includes('@'));
        setEmailsToBeAdded(parsedEmails);
        checkEmailPresence(parsedEmails);
      };
      reader.readAsArrayBuffer(file);
    } else {
      alert('Unsupported file format. Please upload a .csv or .xlsx file.');
    }
  };

  const checkEmailPresence = async (emails) => {
    const mailPresenceMappingResp = await axios.post(
      `${process.env.REACT_APP_BASE_URL}/check-user-existence`, emails
    )
    const mailPresenceMapping = new Map(Object.entries(mailPresenceMappingResp.data));
    setEmailPresenceMap(mailPresenceMapping);

    setRegisterUserEntered(true);
    for (const [key, value] of mailPresenceMapping) {
      if (value === "absent") {
        setRegisterUserEntered(false);
        break;
      }
    }
  }

  const history = useHistory()
  const [isAlertOpen, setAlertState] = useState(false)
  const [isRoleChangeDialogOpen, setIsRoleChangeDialogOpen] = useState(false);
  const [clickedRowRole, setClickedRowRole] = useState('Student')
  const [clickedRowEmail, setClickedRowEmail] = useState('')
  const [newRoleSelected, setNewRoleSelected] = useState('');

  const ActionsButtonComponent = (props) => {
    const [anchorEl, setAnchorEl] = useState(null)
    const menuOpen = Boolean(anchorEl)
   
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget)
    }
    const handleMenuClose = () => {
      setAnchorEl(null)
    }
    return (
      <>

        <Divider />
        <Button
          id="basic-button"
          aria-controls={menuOpen ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={menuOpen ? 'true' : undefined}
          style={{
            border: 'none',
            cursor: 'pointer',
            borderRadius: '5px',
            height: '25px',
            fontWeight: 'bold',
            borderRadius: '100px',
            textAlign: 'left',
          }}
          onClick={handleClick}
        >
          <MoreVertIcon></MoreVertIcon>
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={handleMenuClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem
            onClick={() => {
              history.push(`/display-account/${props.value[1]}`);
              handleMenuClose()
            }}
          >
            View profile
          </MenuItem>
          {props.value[2] && <MenuItem
            onClick={() => {
              setIsRoleChangeDialogOpen(!isRoleChangeDialogOpen);
              setClickedRowRole(props.value[0]);
              setClickedRowEmail(props.value[1]);
              handleMenuClose()
            }}
          >
            Change role
          </MenuItem>}
         
          {props.value[2] && <MenuItem onClick={() => { setDeleteAlertOpen(!isDeleteAlertOpen); setClickedRowEmail(props.value[1]); handleMenuClose() }}>Remove</MenuItem>}
        </Menu>
      </>
    )
  }
  
  const [colDefs, setColDefs] = useState([
    { field: 'Username', flex: 1 },
    {field:'Email', flex:1},
    { field: 'Role', flex: 0.3},
    {
      field: 'Actions',
      headerName: '',
      cellRenderer: ActionsButtonComponent,
      flex: 0.25,
      sortable: false,
      filter: false,
      resizable: false,
    },
  ])

  const [studentTAcolDefs, setStudentTAcolDefs] = useState([
    { field: 'Username', flex: 1 },
    { field: 'Role', flex: 1},
    {
      field: 'Actions',
      headerName: '',
      cellRenderer: ActionsButtonComponent,
      flex: 0.25,
      sortable: false,
      filter: false,
      resizable: false,
    },
  ])
  





  const defaultColDef = useMemo(() => {
    return {
      filter: 'agTextColumnFilter',
      floatingFilter: true,
    }
  }, [])
  const handleClose = () => {
    setAlertState(false)
    setDeleteAlertOpen(false);
    setIsRoleChangeDialogOpen(false);
  }

  const handleContClick = async () => {
    setAlertState(false)

    if (clickedRowRole === 'Instructor') {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/revoke-instructor-access/${clickedRowEmail}`
      )

      window.location.reload()
    } else {
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/upgrade-user/${clickedRowEmail}`
      )

      window.location.reload()
    }
  }

  const handleEmailTextChange = async (event) => {

    const text = event.target.value;
    const emailRegex = /[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}/g;
    const emails = text.match(emailRegex);
    setEmailsToBeAdded(emails);
    checkEmailPresence(emails);

  }

  const handleEnrollClick = async () => {
    if(roleSelectedAdd===''){

      alert("Please select a role");
      return;
    }
    const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v2/courses/${courseId}/students`, {...Object.fromEntries(emailPresenceMap), roleAdded:roleSelectedAdd});
    if (response.status === 204) {
      setReloadFlag(!reloadFlag);
      setAddUserDialogOpen(false);
      setEmailsToBeAdded([]); 
      setEmailPresenceMap(new Map()); 
      setRegisterUserEntered(true); 
      setSelectedFile(null) 
      setRoleSelectedAdd('');
    }
    else {
      alert("some error occurred");
    }
  }

  const handleRoleSelectionContClick = async ()=>{
    if(newRoleSelected===''){
      alert("Please select the new role");
      return;
    }
    
    if(clickedRowRole==='Instructor' && newRoleSelected!='Instructor' && instructorCount===1){
      alert("Atleast one instructor is required. Cannot change role.");
      setNewRoleSelected('');
      return;
    }
    setIsRoleChangeDialogOpen(false);

    const responseDel = await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/v2/courses/${courseId}/student`,{data:{'email':clickedRowEmail}});

    if(responseDel.status===204){
      const responseAdd = await axios.post(`${process.env.REACT_APP_BASE_URL}/api/v2/courses/${courseId}/students`, {[clickedRowEmail]:'present', roleAdded:newRoleSelected});
      if(responseAdd.status!=204){
        alert("some error occurred");
        return;
      }
    }
    setNewRoleSelected('');
    setInstructorCount(0);
    setReloadFlag(!reloadFlag);
    
  }

  const handleDeleteContClick = async () => {
    if(clickedRowRole==='Instructor' && instructorCount===1){
      alert("Atleast one instructor is required. Cannot remove user.");
      return;
    }
    setDeleteAlertOpen(false);

    

    const response = await axios.delete(`${process.env.REACT_APP_BASE_URL}/api/v2/courses/${courseId}/student`,{data:{'email':clickedRowEmail}});
    if(response.status!=204){
      alert("Error in user deletion");
    }
    setInstructorCount(0);
    setReloadFlag(!reloadFlag);
  }

  return (<>
    <React.Fragment>
      <div
        className={'ag-theme-quartz'}
        style={{
          height:'520px',
          paddingBottom: '70px',
        }}
      >
        {/* <div className={`${tabStyles['tab-heading']}`} style={{ position: "relative", display: "flex", justifyContent: "flex-end", alignItems: "center" }}>


          <Fab onClick={() => { setAddUserDialogOpen(true) }} color='primary' style={{ marginRight: "40px", marginBottom: "5px" }}>
            <AddIcon />
          </Fab>
        </div> */}
        <div className={`${tabStyles['tab-heading']}`}>
				People
				{
					
						isCurUserInstructor && (
              <Fab aria-label="add" color="primary" disabled={isLoading} onClick={()=>setAddUserDialogOpen(true)}>
							<Tooltip title="Add User">
								<AddIcon />
							</Tooltip>
						</Fab>
            )
					
				}
			</div>
			<Divider />

        
        <Box sx={{ marginTop:'20px',marginLeft:'5%',marginRight:'5%', typography: 'body1', height: 'calc(100% - 40px)', overflowY: 'auto' }}> {/* Added overflowY for scroll */}
      <AgGridReact
        rowData={rowData}
        columnDefs={isCurUserInstructor? colDefs:studentTAcolDefs}
        defaultColDef={defaultColDef}
        suppressRowClickSelection={true}
        enableCellTextSelection={true}
        domLayout="normal"  
        getRowStyle={(params) => {
          if (params.data && params.data.Role === 'Instructor') {
            return { backgroundColor: '#bbdefb' }; 
          }
          if (params.data && params.data.Role === 'TA') {
            return { backgroundColor: '#FEF9E7' };  
          }
        }}
      />
    </Box>
  

      </div>
      <Modal
        open={addUserDialogOpen}
        onClose={() => { setAddUserDialogOpen(false); }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          component="form"
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',
            border: 'none',
            borderRadius: "10px",
            boxShadow: 24,
            p: 4,
          }}
          noValidate
          autoComplete="off"
        >
          <h4 style={{ color: "black", textAlign: "left", marginBottom: "10px" }}>Enroll Users</h4>
          <Box sx={{width:'150px', marginBottom:'20px'}}>
          <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label">Select role</InputLabel>
  <Select
    labelId="demo-simple-select-label"
    id="demo-simple-select"
    // value={roleSelectedAdd}
    label="Select role"
    onChange={
      (event)=>(setRoleSelectedAdd(event.target.value))
    }
  >
    <MenuItem value="Instructor">Instructor</MenuItem>
    <MenuItem value="TA">Teaching Assistant</MenuItem>
    <MenuItem value="Student">Student</MenuItem>
  </Select>
  </FormControl>
          </Box>
          <TextField
            id="outlined-multiline-static"
            label="Enter emails"
            multiline
            rows={4}
            style={{ width: "100%" }}
            onChange={handleEmailTextChange}
          />

          <div style={{ display: "flex", alignItems: "center", textAlign: "center" }}>
            <hr style={{ flex: "1", border: "none", borderTop: "1px solid #ccc", color: "#7b564d" }} />
            <span style={{ padding: "0 10px", fontWeight: "bold", color: "#7b564d" }}>OR</span>
            <hr style={{ flex: "1", border: "none", borderTop: "1px solid #ccc", color: "#7b564d" }} />
          </div>


          <Box display="flex" alignItems="center" justifyContent="center" >
            <Button
              variant="outlined"
              component="label"
              style={{ width: "100%" }}
              startIcon={<UploadFileIcon />}
            >
              Upload File
              <input
                type="file"
                hidden
                accept=".csv, .xlsx"
                onChange={handleFileChange}
              />
            </Button>
          </Box>

          {selectedFile && (
            <Typography variant="body1" ml={1}>
              {selectedFile.name}
            </Typography>
          )}

          <List
            sx={{ width: '100%', bgcolor: 'background.paper', display: "block", boxShadow: "2", height: "180px", overflow: "auto", marginTop: "30px", borderRadius: "5px" }}
            component="nav"
            aria-labelledby="nested-list-subheader"
            subheader={
              <ListSubheader style={{ backgroundColor: "#f2f0f0", fontWeight: "bold", fontSize: "100%" }} component="div" id="nested-list-subheader">
                Entered Emails:
              </ListSubheader>
            }
            dense={true}
          >
            {emailsToBeAdded ? emailsToBeAdded.map((email, index) => (
              <ListItemButton key={index} style={{ color: "black" }}>
                <ListItemText style={{ display: "flex" }}>
                  <span style={emailPresenceMap.get(email) === 'absent' ? { textDecoration: "line-through" } : {}}>
                    {email}
                  </span>
                  {emailPresenceMap.get(email) === 'absent' && (
                    <span style={{ color: "red", fontSize: "smaller" }}> User not on MyAcademic</span>
                  )}
                </ListItemText>
              </ListItemButton>
            )) : <></>}
          </List>
          {registeredUserEntered ? <></> : <Alert style={{ marginTop: "10px", height: "30px", padding: "0px", textAlign: "center", paddingLeft: "20px" }} severity="warning">Unregistered users can't be enrolled</Alert>}
          <div style={{ textAlign: "center", width: "100%", marginTop: "10px" }}>
            <Button
              onClick={() => { setAddUserDialogOpen(false); setEmailsToBeAdded([]); setEmailPresenceMap(new Map()); setRegisterUserEntered(true); setSelectedFile(null) }}
              style={{ flex: "1", width: "48%", marginRight: "4%" }}
              variant="contained"
            >
              Cancel
            </Button>
            <Button onClick={handleEnrollClick} style={{ flex: "1", width: "48%" }} variant="contained">
              Enroll
            </Button>
          </div>
        </Box>
      </Modal>
      <Dialog
        open={isRoleChangeDialogOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Select new role'}
        </DialogTitle>
        <DialogContent>
        <FormControl>
      <FormLabel id="demo-row-radio-buttons-group-label">Assign role for {clickedRowEmail}</FormLabel>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        onChange={(event)=>(setNewRoleSelected(event.target.value))}
      >
        <FormControlLabel value="Instructor" control={<Radio />} label="Instructor" />
        <FormControlLabel value="TA" control={<Radio />} label="Teaching Assistant (TA)" />
        <FormControlLabel value="Student" control={<Radio />} label="Student" />
      </RadioGroup>
    </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleRoleSelectionContClick} autoFocus>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={isDeleteAlertOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {'Are you sure?'}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            User <b>{clickedRowEmail}</b> will be removed from the course
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleDeleteContClick} autoFocus>
            Continue
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  </>);
}
