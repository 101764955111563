/*eslint-disable*/
import React, { useEffect, useState } from 'react'
import styles from './CreateCourse.module.css'
import ClearIcon from '@mui/icons-material/Clear';
import Modal from '@mui/material/Modal';
import { Button, createTheme, Fab, TextField, ThemeProvider, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

const CourseInfo = ({open, setOpen, course}) => {
    const [courseTitle, setCourseTitle] = useState(course?.name);
    const [isValidTitle, setIsValidTitle] = useState(true);
    const [courseStartDate, setCourseStartDate] = useState(dayjs(course?.startDate)); 
    const [isValidStartDate, setIsValidStartDate] = useState(true); 
    const [courseEndDate, setCourseEndDate] = useState(dayjs(course?.endDate)); 
    const [isValidEndDate, setIsValidEndDate] = useState(true); 
    const [courseDescription, setCourseDescription] = useState(course?.description);
    const [isValidCourseDescription, setIsValidCourseDescription] = useState(true); 

	const theme = createTheme({
		components: {
			MuiFormLabel: {
				styleOverrides: {
					asterisk: { color: "#d32f2f" },
				},
			},
		},
	})

    const handleClose = () => {
        setOpen(false);
    }

	const handleSubmit = () => {
		if (isValidTitle && isValidStartDate && isValidEndDate) {
			editCourse(courseTitle, courseDescription, courseStartDate, courseEndDate);
			handleClose();
		}
	}

    return (
		<div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className={`${styles['modal-container']}`}>
                    <div className={`${styles['row']}`}>
                        <h1 className={`${styles['modal-heading']}`}>{course.name}</h1>
                        <ClearIcon 
                            onClick={handleClose}
                            sx={{
                                fontSize: "2.5em",
                                cursor: "pointer"
                            }}
                        />
                    </div>
					<div 
                        style={{
                            display: "grid",
                            gap: "1em"
                        }}
                    >					
                        <div className={`${styles['to-col']}`} style={{display: "flex", gap: "1em", alignItems: "flex-start", fontStyle: "oblique"}}>
							{(dayjs(course.startDate).format("DD/MM/YYYY")).toString()} to {(dayjs(course.endDate).format("DD/MM/YYYY")).toString()}
                        </div>
                        {course.description}
					</div>
                </div>
            </Modal>
        </div>
	)
}

export default CourseInfo 