import {
    defaultCategories,
    defaultCategory,
    defaultCategoryItem,
    // defaultPeople
} from '../../utils'
import {
    ACHIEVE_TARGET_GRADE,
    COURSE_CATEGORY_ADD,
    COURSE_CATEGORY_DELETE,
    COURSE_CATEGORY_ITEM_ADD,
    COURSE_CATEGORY_ITEM_DELETE,
    COURSE_CATEGORY_ITEM_DESCRIPTION_UPDATE,
    COURSE_CATEGORY_ITEM_GRADE_UPDATE,
    COURSE_CATEGORY_ITEM_KNOWN_SCORE_SWITCH,
    COURSE_CATEGORY_ITEM_MAX_POINTS_UPDATE,
    COURSE_CATEGORY_ITEM_NAME_UPDATE,
    COURSE_CATEGORY_ITEM_WEIGHT_UPDATE,
    COURSE_CATEGORY_MAX_POINTS_UPDATE,
    COURSE_CATEGORY_NAME_UPDATE,
    COURSE_CATEGORY_TYPE_UPDATE,
    COURSE_CATEGORY_WEIGHT_UPDATE,
    COURSE_DESCRIPTION_UPDATE,
    COURSE_GRADES_DELETE_FAILURE,
    COURSE_GRADES_DELETE_REQUEST,
    COURSE_GRADES_DELETE_SUCCESS,
    COURSE_GRADES_SAVE_FAILURE,
    COURSE_GRADES_SAVE_REQUEST,
    COURSE_GRADES_SAVE_SUCCESS,
    COURSE_GRADES_UPDATE_FAILURE,
    COURSE_GRADES_UPDATE_REQUEST,
    COURSE_GRADES_UPDATE_SUCCESS,
    COURSE_MEETING_UPDATE,
    COURSE_NAME_UPDATE,
    COURSE_NOTES_UPDATE,
    COURSE_SYLLABUS_UPDATE,
    COURSE_TARGET_GRADE_UPDATE,
    MODIFY_KNOWN_SCORES,
    RESET_COURSE_DATA,
    SAVED_COURSE_LOAD_FAILURE,
    SAVED_COURSE_LOAD_REQUEST,
    SAVED_COURSE_LOAD_SUCCESS,
    UPDATE_COURSE_METER_DIVISIONS,
    COURSE_STUDENT_ADDITION_SUCCESS,
    COURSE_SET_GRADE_PERSONAL_SUCCESS,
    // COURSE_STUDENT_ADDITION_FAILURE
    // MODIFICATIONS
} from './courseTypes'
import {
    getCategoriesReducedData,
    getItemWeightsOfCategories,
    updateCategoriesWithTypeChanges,
} from './helpers/categoryHelper'

const initialState = {
    targetGrade: 85,
    courseName: '',
    createdBy: '',
    courseDescription: '',
    courseSyllabus: '',
    courseMeetingDetails: '',
    // Modification:
    gradeMaps: [],
    peopleList: [],
    categories: defaultCategories,
    recommendedGrades: defaultCategories.map((category) =>
        Array(category.items.length).fill(0)
    ),
    gradesInAchievingTargetGrade: defaultCategories.map((category) =>
        Array(category.items.length).fill(0)
    ),
    achievingTargetGrade: false,
    meterDivisions: [70, 80],
    maxAchievableGrade: 0,
    ...getCategoriesReducedData(defaultCategories, false, []),
    // saved course
    savedCourseNotes: '',
    loadingSavedCourse: false,
    loadingSavedCourseMessage: '',
    savedCourseLoadSuccess: false,
    savedCourseLoadError: '',
    courseOperating: false,
    courseOperatingMessage: '',
    courseOperationSuccess: false,
    courseOperationError: '',
    savedCourseId: null,
    lastUpdatedOn: null,
    isDeleted: false,
    successNotificationMessage: '',
    failureNotificationMessage: '',
    savedCourseName: '',
    isTemplate: false,
}

const getUpdatedStateForCategoryIndex = (
    state,
    categoryIndex,
    updatedCategoryFieldKey,
    updatedCategoryFieldValue
) => {
    let updatedState
    if (state.achievingTargetGrade && updatedCategoryFieldKey === 'grade') {
        const newGradesInAchievingTargetGrade =
            state.gradesInAchievingTargetGrade
        newGradesInAchievingTargetGrade[categoryIndex] =
            updatedCategoryFieldValue
        const categoriesReducedData = getCategoriesReducedData(
            state.categories,
            state.achievingTargetGrade,
            newGradesInAchievingTargetGrade
        )
        updatedState = {
            ...state,
            gradesInAchievingTargetGrade: newGradesInAchievingTargetGrade,
            ...categoriesReducedData,
        }
    } else {
        const updatedCategories = state.categories
        const category = updatedCategories[categoryIndex]
        const updatedCategoryItem = {
            ...category,
            [updatedCategoryFieldKey]: updatedCategoryFieldValue,
        }
        updatedCategories.splice(categoryIndex, 1, updatedCategoryItem)
        updatedState = {
            ...state,
            categories: [...updatedCategories], // Done to trigger UI update of other categories too
        }
        if (updatedCategoryFieldKey === 'categoryType') {
            updateCategoriesWithTypeChanges(
                updatedCategories,
                categoryIndex,
                updatedCategoryFieldValue
            )
        }
        if (
            ['weight', 'categoryType'].indexOf(updatedCategoryFieldKey) !== -1
        ) {
            const categoriesReducedData = getCategoriesReducedData(
                updatedCategories,
                state.achievingTargetGrade,
                state.gradesInAchievingTargetGrade
            )
            updatedState = {
                ...updatedState,
                ...categoriesReducedData,
            }
        }
    }
    return updatedState
}

const getUpdatedStateForCategoryItemIndex = (
    state,
    categoryIndex,
    itemIndex,
    updatedItemFieldKey,
    updatedItemFieldValue
) => {
    let updatedState
    if (state.achievingTargetGrade && updatedItemFieldKey === 'grade') {
        const newGradesInAchievingTargetGrade =
            state.gradesInAchievingTargetGrade
        newGradesInAchievingTargetGrade[categoryIndex][itemIndex] =
            updatedItemFieldValue
        const categoriesReducedData = getCategoriesReducedData(
            state.categories,
            state.achievingTargetGrade,
            newGradesInAchievingTargetGrade
        )
        updatedState = {
            ...state,
            gradesInAchievingTargetGrade: newGradesInAchievingTargetGrade,
            ...categoriesReducedData,
        }
    } else {
        const updatedCategories = state.categories
        const updatedCategory = updatedCategories[categoryIndex]
        const categoryItem = updatedCategory.items[itemIndex]
        const updatedCategoryItem = {
            ...categoryItem,
            [updatedItemFieldKey]: updatedItemFieldValue,
        }
        updatedCategory.items.splice(itemIndex, 1, updatedCategoryItem)
        updatedCategories.splice(categoryIndex, 1, updatedCategory)
        updatedState = {
            ...state,
            categories: [...updatedCategories], // Done to trigger UI update of other categories too
        }
        if (
            ['knownScores', 'grade', 'maximumPoints', 'weight'].indexOf(
                updatedItemFieldKey
            ) !== -1
        ) {
            const categoriesReducedData = getCategoriesReducedData(
                updatedCategories,
                state.achievingTargetGrade,
                state.gradesInAchievingTargetGrade
            )
            updatedState = {
                ...updatedState,
                ...categoriesReducedData,
            }
        }
    }
    return updatedState
}

const addCourseCategory = (state) => {
    const updatedCategories = [...state.categories, defaultCategory]
    const categoriesReducedData = getCategoriesReducedData(
        updatedCategories,
        state.achievingTargetGrade,
        state.gradesInAchievingTargetGrade
    )
    return {
        ...state,
        categories: [...updatedCategories], // Done to trigger UI update of other categories too
        gradesInAchievingTargetGrade: [
            ...state.gradesInAchievingTargetGrade,
            Array(defaultCategory.items.length).fill(0),
        ],
        recommendedGrades: [
            ...state.recommendedGrades,
            Array(defaultCategory.items.length).fill(0),
        ],
        ...categoriesReducedData,
    }
}

const deleteCourseCategory = (state, categoryIndex) => {
    const updatedCategories = state.categories
    updatedCategories.splice(categoryIndex, 1)
    const updatedGradesInAchievingTargetGrade =
        state.gradesInAchievingTargetGrade
    updatedGradesInAchievingTargetGrade.splice(categoryIndex, 1)
    const categoriesReducedData = getCategoriesReducedData(
        updatedCategories,
        state.achievingTargetGrade,
        state.gradesInAchievingTargetGrade
    )
    return {
        ...state,
        categories: updatedCategories,
        gradesInAchievingTargetGrade: updatedGradesInAchievingTargetGrade,
        ...categoriesReducedData,
    }
}

const addCourseCategoryItem = (state, categoryIndex) => {
    const updatedCategories = [...state.categories]
    const updatedCategory = updatedCategories[categoryIndex]
    const updatedCategoryItems = [...updatedCategory.items, defaultCategoryItem]
    updatedCategory.items = updatedCategoryItems
    const updatedRecommendedGrades = [...state.recommendedGrades]
    updatedRecommendedGrades[categoryIndex] = [
        ...updatedRecommendedGrades[categoryIndex],
        0,
    ]
    const updatedGradesInAchievingTargetGrades = [
        ...state.gradesInAchievingTargetGrade,
    ]
    updatedGradesInAchievingTargetGrades[categoryIndex] = [
        ...updatedGradesInAchievingTargetGrades[categoryIndex],
        0,
    ]
    const categoriesReducedData = getCategoriesReducedData(
        updatedCategories,
        state.achievingTargetGrade,
        updatedGradesInAchievingTargetGrades
    )
    return {
        ...state,
        categories: [...updatedCategories], // Done to trigger UI update of other categories too
        recommendedGrades: updatedRecommendedGrades,
        gradesInAchievingTargetGrade: updatedGradesInAchievingTargetGrades,
        ...categoriesReducedData,
    }
}

const deleteCourseCategoryItem = (state, categoryIndex, itemIndex) => {
    const updatedCategories = state.categories
    updatedCategories[categoryIndex].items.splice(itemIndex, 1)
    const updatedGradesInAchievingTargetGrade =
        state.gradesInAchievingTargetGrade
    updatedGradesInAchievingTargetGrade[categoryIndex].splice(itemIndex, 1)
    const updatedRecommendedGrades = state.recommendedGrades
    updatedRecommendedGrades[categoryIndex].splice(itemIndex, 1)
    const categoriesReducedData = getCategoriesReducedData(
        updatedCategories,
        state.achievingTargetGrade,
        updatedGradesInAchievingTargetGrade
    )
    return {
        ...state,
        categories: [...updatedCategories], // Done to trigger UI update of other categories too
        recommendedGrades: updatedRecommendedGrades,
        gradesInAchievingTargetGrade: updatedGradesInAchievingTargetGrade,
        ...categoriesReducedData,
    }
}

const achieveTargetGrade = (state) => {
    const { knownGradesSum, unknownGradesWeightSum, maxAchievableGrade } = state
    const leastFactorForTarget =
        (state.targetGrade - knownGradesSum) / unknownGradesWeightSum
    if (leastFactorForTarget > 1) {
        // eslint-disable-next-line no-alert
        window.alert(
            `Please lower your target grade. Maximum achievable grade is ${maxAchievableGrade.toFixed(
                2
            )}`
        )
        return state
    }

    const newGradesInAchievingTargetGrade = state.gradesInAchievingTargetGrade
    let totalGradesInAchievingTargetGrades = 0
    let itemsPerPointWeights = [] // contains unknown score item's weight per point and indexes
    const categoriesItemWeights = getItemWeightsOfCategories(state.categories)
    state.categories.forEach((category, categoryIdx) => {
        const itemWeights = categoriesItemWeights[categoryIdx]
        category.items.forEach((item, itemIdx) => {
            const { knownScores, maximumPoints } = item
            const weight = itemWeights[itemIdx]
            if (weight === 0) {
                newGradesInAchievingTargetGrade[categoryIdx][itemIdx] = 0
            } else if (!knownScores) {
                const suggestedScore = maximumPoints * leastFactorForTarget
                const roundedSuggestedScore = Math.ceil(suggestedScore)
                newGradesInAchievingTargetGrade[categoryIdx][itemIdx] =
                    roundedSuggestedScore
                totalGradesInAchievingTargetGrades +=
                    (roundedSuggestedScore / maximumPoints) * weight
                itemsPerPointWeights.push({
                    perPointWeight: weight / maximumPoints,
                    index: [categoryIdx, itemIdx],
                    pointsToRemove: 0,
                })
            }
        })
    })
    // Taking care of the extra grades
    let extraGrades =
        totalGradesInAchievingTargetGrades -
        (state.targetGrade - knownGradesSum)
    if (extraGrades > 0) {
        itemsPerPointWeights = itemsPerPointWeights.filter(
            (item) => item.perPointWeight <= extraGrades
        )
        itemsPerPointWeights.sort(
            (item1, item2) => item2.perPointWeight - item1.perPointWeight
        )
        const totalPerPointWeight = itemsPerPointWeights.reduce(
            (sum, item) => sum + item.perPointWeight,
            0
        )
        if (totalPerPointWeight > 0) {
            const pointsToRemoveFromAll = Math.floor(
                extraGrades / totalPerPointWeight
            )
            extraGrades -= pointsToRemoveFromAll * totalPerPointWeight
            let i = 0
            while (extraGrades > 0) {
                if (i === itemsPerPointWeights.length) {
                    if (
                        itemsPerPointWeights[i - 1].perPointWeight > extraGrades
                    ) {
                        // least perPointWeight is more than the extra grade
                        break
                    }
                    i = 0
                } else {
                    if (itemsPerPointWeights[i].perPointWeight <= extraGrades) {
                        itemsPerPointWeights[i].pointsToRemove += 1
                        extraGrades -= itemsPerPointWeights[i].perPointWeight
                    }
                    i += 1
                }
            }
            itemsPerPointWeights.forEach((item) => {
                const [categoryIdx, itemIdx] = item.index
                newGradesInAchievingTargetGrade[categoryIdx][itemIdx] -=
                    pointsToRemoveFromAll + item.pointsToRemove
            })
        }
    }
    const categoriesReducedData = getCategoriesReducedData(
        state.categories,
        true,
        newGradesInAchievingTargetGrade
    )
    return {
        ...state,
        achievingTargetGrade: true,
        recommendedGrades: newGradesInAchievingTargetGrade.map((arr) =>
            arr.slice()
        ), // Deep copying 1 level
        gradesInAchievingTargetGrade: newGradesInAchievingTargetGrade,
        ...categoriesReducedData,
    }
}

const modifyKnownScores = (state) => ({
    ...state,
    achievingTargetGrade: false,
})

const reducer = (state = initialState, action = {}) => {
    switch (action.type) {
        case COURSE_STUDENT_ADDITION_SUCCESS:
            console.log('I am adding students')
            console.log(action.payload)
            return {
                ...state,
                peopleList: [...state.peopleList, action.payload],
            }
        case COURSE_SET_GRADE_PERSONAL_SUCCESS:
            console.log('Setting grades');
            console.log(action.payload);
            return {
                ...state,
                gradeMaps: [...state.gradeMaps, action.payload],
            }
        case COURSE_TARGET_GRADE_UPDATE:
            return {
                ...state,
                targetGrade: action.payload,
            }
        case COURSE_NAME_UPDATE:
            return {
                ...state,
                courseName: action.payload,
            }
        case COURSE_DESCRIPTION_UPDATE:
            return {
                ...state,
                courseDescription: action.payload,
            }
        case COURSE_SYLLABUS_UPDATE:
            return {
                ...state,
                courseSyllabus: action.payload,
            }
        case COURSE_MEETING_UPDATE:
            return {
                ...state,
                courseMeetingDetails: action.payload,
            }
        case COURSE_NOTES_UPDATE:
            return {
                ...state,
                savedCourseNotes: action.payload,
            }
        case COURSE_CATEGORY_NAME_UPDATE:
            return getUpdatedStateForCategoryIndex(
                state,
                action.payload[0],
                'name',
                action.payload[1]
            )
        case COURSE_CATEGORY_TYPE_UPDATE:
            return getUpdatedStateForCategoryIndex(
                state,
                action.payload[0],
                'categoryType',
                action.payload[1]
            )
        case COURSE_CATEGORY_MAX_POINTS_UPDATE:
            return getUpdatedStateForCategoryIndex(
                state,
                action.payload[0],
                'maximumPoints',
                action.payload[1]
            )
        case COURSE_CATEGORY_WEIGHT_UPDATE:
            return getUpdatedStateForCategoryIndex(
                state,
                action.payload[0],
                'weight',
                action.payload[1]
            )
        case COURSE_CATEGORY_ITEM_NAME_UPDATE:
            return getUpdatedStateForCategoryItemIndex(
                state,
                action.payload[0],
                action.payload[1],
                'name',
                action.payload[2]
            )
        case COURSE_CATEGORY_ITEM_DESCRIPTION_UPDATE:
            return getUpdatedStateForCategoryItemIndex(
                state,
                action.payload[0],
                action.payload[1],
                'description',
                action.payload[2]
            )
        case COURSE_CATEGORY_ITEM_WEIGHT_UPDATE:
            return getUpdatedStateForCategoryItemIndex(
                state,
                action.payload[0],
                action.payload[1],
                'weight',
                action.payload[2]
            )
        case COURSE_CATEGORY_ITEM_KNOWN_SCORE_SWITCH:
            return getUpdatedStateForCategoryItemIndex(
                state,
                action.payload[0],
                action.payload[1],
                'knownScores',
                action.payload[2]
            )
        case COURSE_CATEGORY_ITEM_GRADE_UPDATE:
            return getUpdatedStateForCategoryItemIndex(
                state,
                action.payload[0],
                action.payload[1],
                'grade',
                action.payload[2]
            )
        case COURSE_CATEGORY_ITEM_MAX_POINTS_UPDATE:
            return getUpdatedStateForCategoryItemIndex(
                state,
                action.payload[0],
                action.payload[1],
                'maximumPoints',
                action.payload[2]
            )
        case COURSE_CATEGORY_ADD:
            return addCourseCategory(state)
        case COURSE_CATEGORY_DELETE:
            return deleteCourseCategory(state, action.payload)
        case COURSE_CATEGORY_ITEM_ADD:
            return addCourseCategoryItem(state, action.payload)
        case COURSE_CATEGORY_ITEM_DELETE:
            return deleteCourseCategoryItem(
                state,
                action.payload[0],
                action.payload[1]
            )
        case ACHIEVE_TARGET_GRADE:
            return achieveTargetGrade(state)
        case MODIFY_KNOWN_SCORES:
            return modifyKnownScores(state)
        case UPDATE_COURSE_METER_DIVISIONS:
            return {
                ...state,
                meterDivisions: action.payload,
            }
        case COURSE_GRADES_SAVE_REQUEST:
            return {
                ...state,
                courseOperating: true,
                courseOperatingMessage: 'Saving the course...',
                courseOperationSuccess: false,
                courseOperationError: '',
                savedCourseId: null,
            }
        case COURSE_GRADES_SAVE_SUCCESS:
            return {
                ...state,
                courseOperating: false,
                courseOperatingMessage: '',
                courseOperationSuccess: true,
                courseOperationError: '',
                savedCourseId: action.payload.id,
            }
        case COURSE_GRADES_SAVE_FAILURE:
            return {
                ...state,
                courseOperating: false,
                courseOperatingMessage: '',
                courseOperationSuccess: false,
                courseOperationError: action.payload,
                savedCourseId: null,
            }
        case RESET_COURSE_DATA:
            return {
                ...initialState,
            }
        case SAVED_COURSE_LOAD_REQUEST:
            return {
                ...state,
                savedCourseName: '',
                courseName: '',
                courseDescription: '',
                courseSyllabus: '',
                courseMeetingDetails: '',
                // Modifications
                peopleList: [],
                savedCourseNotes: '',
                loadingSavedCourse: true,
                loadingSavedCourseMessage: 'Loading course details...',
                savedCourseLoadSuccess: false,
                savedCourseLoadError: '',
                categories: [],
                meterDivisions: [70, 80],
                lastUpdatedOn: null,
                isDeleted: false,
                isTemplate: false,
            }
        case SAVED_COURSE_LOAD_SUCCESS: {
            try{
                const savedCategories = action.payload.categories
                const categoriesReducedData = getCategoriesReducedData(
                    savedCategories,
                    state.achievingTargetGrade,
                    state.gradesInAchievingTargetGrade
                )
                return {
                    ...state,
                    ...categoriesReducedData,
                    savedCourseName: action.payload.name || '',
                    courseName: action.payload.name || '',
                    createdBy: action.payload.createdBy ? action.payload.createdBy.toString() : '',
                    courseDescription: action.payload.description || '',
                    courseSyllabus: action.payload.syllabus || '',
                    courseMeetingDetails: action.payload.meetingDetails || '',
                    // Modifications
                    gradeMaps: action.payload.gradeMaps || [],
                    people: action.payload.people || [],
                    savedCourseNotes: action.payload.notes || '',
                    loadingSavedCourse: false,
                    loadingSavedCourseMessage: '',
                    savedCourseLoadSuccess: true,
                    savedCourseLoadError: '',
                    categories: savedCategories,
                    recommendedGrades: savedCategories.map((category) =>
                        Array(category.items.length).fill(0)
                    ),
                    gradesInAchievingTargetGrade: savedCategories.map((category) =>
                        Array(category.items.length).fill(0)
                    ),
                    meterDivisions: action.payload.meterDivisions,
                    lastUpdatedOn: action.payload.lastUpdatedOn
                    ? new Date(action.payload.lastUpdatedOn)
                    : '',
                    isDeleted: false,
                    isTemplate: action.payload.isTemplate || false,
                    targetGrade: action.payload.targetGrade || 85,
                }
            } catch (error) {
                console.log('Error processing SAVED_COURSE_LOAD_SUCCESS:', error);
                return {
                    ...state,
                };
            }
        }
        case SAVED_COURSE_LOAD_FAILURE:
            return {
                ...state,
                savedCourseName: '',
                courseName: '',
                courseDescription: '',
                courseSyllabus: '',
                courseMeetingDetails: '',
                peopleList: [],
                savedCourseNotes: '',
                loadingSavedCourse: false,
                loadingSavedCourseMessage: '',
                savedCourseLoadSuccess: false,
                savedCourseLoadError: action.payload,
                categories: [],
                meterDivisions: [70, 80],
                lastUpdatedOn: null,
                isDeleted: false,
                isTemplate: false,
            }
        case COURSE_GRADES_UPDATE_REQUEST:
            return {
                ...state,
                courseOperating: true,
                courseOperatingMessage: 'Updating saved course...',
                courseOperationSuccess: false,
                courseOperationError: '',
                successNotificationMessage: '',
                failureNotificationMessage: '',
            }
        case COURSE_GRADES_UPDATE_SUCCESS:
            return {
                ...state,
                savedCourseName: state.courseName,
                courseOperating: false,
                courseOperatingMessage: '',
                courseOperationSuccess: true,
                courseOperationError: '',
                successNotificationMessage: 'Course updated successfully!',
            }
        case COURSE_GRADES_UPDATE_FAILURE:
            return {
                ...state,
                courseOperating: false,
                courseOperatingMessage: '',
                courseOperationSuccess: false,
                courseOperationError: action.payload,
                failureNotificationMessage: 'Course could not be updated',
            }
        case COURSE_GRADES_DELETE_REQUEST:
            return {
                ...state,
                courseOperating: true,
                courseOperatingMessage: 'Deleting saved course...',
                courseOperationSuccess: false,
                courseOperationError: '',
                isDeleted: false,
            }
        case COURSE_GRADES_DELETE_SUCCESS:
            return {
                ...state,
                courseOperating: false,
                courseOperatingMessage: '',
                courseOperationSuccess: true,
                courseOperationError: '',
                isDeleted: true,
            }
        case COURSE_GRADES_DELETE_FAILURE:
            return {
                ...state,
                courseOperating: false,
                courseOperatingMessage: '',
                courseOperationSuccess: false,
                courseOperationError: action.payload,
                isDeleted: false,
            }
        default:
            return state
    }
}

export default reducer
