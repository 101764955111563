import React, { Fragment, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { BrowserRouter, Route, Switch, Redirect,useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import NavBar from './components/commons/NavBar'
import Footer from './components/commons/Footer'
import GradeAnalyzer from './components/GradeAnalyzer'
import Login from './components/Login'
import Register from './components/Register'
import { getCurrentUser as getCurrentUserRedux } from './redux'
import TopLoader from './components/commons/TopLoader'
import VerifyUser from './components/VerifyUser'
import GenerateResetPasswordEmail from './components/GenerateResetPasswordEmail'
import ResetPassword from './components/ResetPassword'
import LandingPage from './components/LandingPage'
import Careers from './components/Careers'
import Quiz from './components/quiz/Quiz'

// import CreateQuiz from './components/quiz/CreateQuiz'

// import CreateQuizQuestion from './components/quiz/CreateQuizList'
import QuizList from './components/quiz/QuizList'
import CoursesList from './components/courses/CoursesList'
import QuizCategories from './components/quiz/QuizCategories'
import ContactUs from './components/ContactUs'
import CoursesHome from './components/courses/CoursesHome'
import MyAccount from './components/MyAccount'
import Requests from './components/Requests'
import FloatingButton from './components/commons/FloatingButton'
import CourseCards from './components/canvas/CoursesInfo'
import CourseDetails from './components/canvas/CourseDetails'
import CanvasQuiz from './components/canvas/CanvasQuiz'
import CanvasAssignments from './components/canvas/canvasCourseAssignments.jsx'
import DisplayAccount from './components/courses/CoursesHome/Tabs/DisplayAccount.jsx'
import Home from './components/canvas/CanvasCoursHome'
import Modules from './components/canvas/CanvasCourseModules'
import CanvasCreateQuiz from './components/canvas/CanvasCreateQuiz'
import MyAccountForm from './components/MyAccountForm'
import CanvasRequestAccess from './components/canvas/CanvasRequestAccess'
import QuizManagement from './components/quiz/QuizManage.jsx'
import UploadQuizQuestion from './components/quiz/uploadQuizQuestions.jsx'
import manageUsers from './components/ManageUsers.jsx'
import ManageUsers from './components/ManageUsers.jsx'
import QuizNew from './components/quiz-new/Quiz.jsx'
import CreateQuiz from './components/quiz-new/CreateQuiz';
import TuringUserValidation from './components/forms/TusingUserValidation.jsx'
import Courses from './components/courses-new/Courses';
import Course from './components/courses-new/Course.jsx'


function Routes({ userData, getCurrentUser }) {
    useEffect(() => {
        if (
            userData.user === undefined ||
            (userData.user && userData.user.id !== undefined)
        )
            return
        getCurrentUser()
        
    }, [userData.user, getCurrentUser])

    const [isValidated , setIsValidated] = useState(localStorage.getItem("validated"));
   
    return (
        <>
            {userData && userData.loading ? (
                <TopLoader message={userData.loadingMessage} />
            ) : (
                ''
            )}
            <BrowserRouter>
                {isValidated && <NavBar />}
                <Switch>
                    {/* Specific routes first */}
                    {isValidated ? (
                        <>
                            <Route exact path="/" component={LandingPage} /> 
                            <Route exact path="/contact-us" component={ContactUs} />
                            <Route exact path="/display-account/:email" component={DisplayAccount} />
                            <Route exact path="/careers" component={Careers} />
                            <Route exact path="/grade-analyzer" component={GradeAnalyzer} />
                            <Route exact path="/login" component={Login} />
                            <Route exact path="/register" component={Register} />
                            <Route exact path="/verify-user" component={VerifyUser} />
                            <Route exact path="/manage-users" component={ManageUsers} />
                            <Route exact path="/reset-password/generate" component={GenerateResetPasswordEmail} />
                            <Route exact path="/reset-password" component={ResetPassword} />
                            <Route exact path="/courses" component={Courses} />
                            <Route exact path="/course/:id" component={Course} />
                            <Route exact path="/quiz/categories" component={QuizCategories} />
                            <Route exact path="/quiz/create-quiz" component={CreateQuiz} />
                            <Route exact path="/quiz/manage/:courseId" component={CreateQuiz} />
                            <Route exact path="/manageQuestions" component={UploadQuizQuestion} />
                            <Route exact path="/quiz/:quiz_id" component={QuizNew} />
                            <Route exact path="/quizzes/:category" component={QuizList} />
                            <Route exact path="/canvas" component={CanvasRequestAccess} />
                            <Route exact path="/myaccount" component={MyAccount} />
                            <Route exact path="/upgrade-form/:user" component={MyAccountForm} />
                            <Route exact path="/courses/canvas" component={CourseCards} />
                            <Route exact path="/courses/canvas/:course_id" component={CourseDetails} />
                            <Route exact path="/courses/canvas/:course_id/home" component={Home} />
                            <Route exact path="/courses/canvas/:course_id/modules" component={Modules} />
                            <Route exact path="/courses/canvas/:course_id/quiz" component={CanvasQuiz} />
                            <Route exact path="/courses/canvas/:course_id/assignments" component={CanvasAssignments} />

                            <Route exact path="/v1/courses" component={CoursesList} />
                            <Route exact path="/v1/course/home/:course_id" component={CoursesHome} />
                            <Route exact path="/v1/courses/canvas/:course_id/quiz/edit" component={CanvasCreateQuiz} />
                            <Route exact path="/v1/course/:course_id" component={GradeAnalyzer} />

                            
                        </>
                    ) : (
                        <Route
                            path="/validate"
                            render={() => <TuringUserValidation onValidate={() => setIsValidated(true)} />}
                        />
                    )}
                    
                    <Route render={() => <Redirect to={isValidated ? "/" : "/validate"} />} />
                    
                </Switch>

                {isValidated && (
                    <>
                        <FloatingButton />
                        <Footer />
                    </>
                )}
            </BrowserRouter>
        </>
    )
}


Routes.propTypes = {
    userData: PropTypes.shape({
        loading: PropTypes.bool,
        loadingMessage: PropTypes.string,
        user: PropTypes.shape({
            id: PropTypes.string,
        }),
    }).isRequired,
    getCurrentUser: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
    userData: state.userData,
})

const mapDispatchToProps = (dispatch) => ({
    getCurrentUser: () => dispatch(getCurrentUserRedux()),
})

export default connect(mapStateToProps, mapDispatchToProps)(Routes)
