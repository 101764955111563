/*eslint-disable*/
import React, { useState } from 'react'
import modalStyles from '../../../../css/Modal.module.css';
import ClearIcon from '@mui/icons-material/Clear';
import Modal from '@mui/material/Modal';
import { Button, createTheme, Fab, TextField, ThemeProvider, Tooltip } from '@mui/material';

const EditModule = ({open, setOpen, moduleTitle, setModuleTitle, handleEdit}) => {
    const [isValidTitle, setIsValidTitle] = useState(true); 

	const theme = createTheme({
		components: {
			MuiFormLabel: {
				styleOverrides: {
					asterisk: { color: "#d32f2f" },
				},
			},
		},
	})

    const handleClose = () => {
        setOpen(false);
    }

	const handleSubmit = () => {
		if (isValidTitle) {
            handleEdit();
		}
	}

    return (
		<div>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<div className={`${modalStyles['container']}`}>
					<div className={`${modalStyles['top-container']}`}>
						<h1 className={`${modalStyles['heading']}`}>Edit Module</h1>
						<ClearIcon 
							onClick={handleClose}
							sx={{
								fontSize: "2.5em",
								cursor: "pointer"
							}}
						/>
					</div>
					<div className={`${modalStyles['main-container']}`}>
					<div style={{display: "flex", gap: "1em", alignItems: "flex-start"}}>
							<ThemeProvider theme={theme}>
								<TextField
									autoFocus={true}
									id="module-title"
									label="Module Title"
									onBlur={() => {
										if (moduleTitle === '') {
											setIsValidTitle(false);
										}
									}}
									onChange={(e) => {setIsValidTitle(true); setModuleTitle(e.target.value);}}
									value={moduleTitle}
									fullWidth
									required
									color={!isValidTitle ? 'error' : 'primary'}
									error={!isValidTitle}
									helperText={!isValidTitle ? '* Course title is required' : ''}
								/>
							</ThemeProvider>
                        </div>	
					</div>
					<div className={`${modalStyles['button-container']}`}>
						<Button
                            className={`btn btn-primary ${modalStyles['add-to-course-btn']}`}
                            onClick={handleSubmit}
                            variant='contained'
                            size='large'
                        >
                            Edit
                        </Button>
					</div>
				</div>
			</Modal>
        </div>
	)
}

export default EditModule 