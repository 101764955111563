/*eslint-disable*/
import React, { useEffect, useRef, useState } from 'react';
import modalStyles from '../../css/Modal.module.css';
import { Backdrop, Button, CircularProgress, Modal } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { Document } from 'react-pdf'
import fileStyles from './Files.module.css'

const FileDisplayModal = ({open, handleClose, fileKey, fileBlob}) => {
    const { id: courseId } = useParams()

    const fileExtension = fileKey?.split('.').pop().toLowerCase() || '';

    // Map common file extensions to MIME types
    const mimeTypes = {
        pdf: 'application/pdf',
        doc: 'application/msword',
        docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        png: 'image/png',
        jpg: 'image/jpeg',
        jpeg: 'image/jpeg',
        gif: 'image/gif',
        txt: 'text/plain',
        csv: 'text/csv',
        xls: 'application/vnd.ms-excel',
        xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        zip: 'application/zip',
        pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
        ppt: 'application/vnd.ms-powerpoint'
    };

    // Set default MIME type for unknown extensions
    const mimeType = mimeTypes[fileExtension] || 'application/octet-stream';


    // Create a URL for the Blob and trigger a download
    const url = window.URL.createObjectURL(new Blob([fileBlob], {type: mimeType}));

    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <div className={fileStyles['container']}>
                {
                    fileKey &&
                    <iframe title='file' className={`${fileStyles['container']} ${fileExtension === "txt" ? fileStyles['container-bg-white'] : ''}`} src={url} />
                }
                <Backdrop
                    sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                    open={fileKey === null}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </Modal>
    )
}

export default FileDisplayModal