/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { Accordion, AccordionActions, AccordionDetails, AccordionSummary, Backdrop, CircularProgress, Divider, Fab, Skeleton, Tab, Tabs, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import tabStyles from '../Tab.module.css';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import styles from './Modules.module.css'
import { Delete, DragIndicator, Edit } from '@mui/icons-material';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import Files from '../../../commons/Files';
import CreateModule from './CreateModule';
import axios from 'axios';
import FileDisplayModal from '../../../commons/FileDisplayModal';
import UploadFileModal from '../../../commons/UploadFileModal';
import EditModule from './EditModule';
import DeleteModuleModal from './DeleteModuleModal';

const Modules = ({isLoading, role}) => {
    const { id: courseId } = useParams();
	const [modules, setModules] = useState([]);
	const [isCreateModuleModalOpen, setIsCreateModuleModalOpen] = useState(false);
	const [isBackdropOpen, setIsBackdropOpen] = useState(false);
	const [selectedModule, setSelectedModule] = useState(null);
	const [isUploadFileModalOpen, setIsUploadFileModalOpen] = useState(false);
    const [deleteModuleId, setDeleteModuleId] = useState(-1); 
    const [deleteModuleTitle, setDeleteModuleTitle] = useState(""); 
	const [isDeleteModuleModalOpen, setIsDeleteModuleModalOpen] = useState(false);
	const [editModuleId, setEditModuleId] = useState(-1); 
    const [editModuleTitle, setEditModuleTitle] = useState(""); 
	const [isEditModuleModalOpen, setIsEditModuleModalOpen] = useState(false);

	const closeFileModal = () => {setIsUploadFileModalOpen(false)}

	const accept = '.pdf, .docx, .doc, .pptx, .xlsx, .txt'
	
	const openCreateModuleModal = () => {
		setIsCreateModuleModalOpen(true);
	}

	const openBackdrop = () => {
		setIsBackdropOpen(true);
	}

	const closeBackdrop = () => {
		setIsBackdropOpen(false);
	}

	const handleEditModule = async () => {
		try {
			await axios({
				method: 'patch',
				url: `${process.env.REACT_APP_BASE_URL}/api/v2/course/${courseId}/module/${editModuleId}`,
				data: {
					moduleName: editModuleTitle,
				},
				headers: { 'Content-Type': 'application/json' }
			})
			setEditModuleId(-1);
			setEditModuleTitle("");
			setIsEditModuleModalOpen(false);
			getModules();
		} catch (error) {
			console.log(error);
		}
	}

	const deleteModule = async () => {
		try {
			await axios({
				method: 'delete',
				url: `${process.env.REACT_APP_BASE_URL}/api/v2/course/${courseId}/module/${deleteModuleId}`,
				headers: { 'Content-Type': 'application/json' }
			})
			setDeleteModuleId(-1);
			setDeleteModuleTitle("");
			setIsDeleteModuleModalOpen(false);
			getModules();
		} catch (error) {
			console.log(error);
		}
	}
 
	const deleteFile = async (fileName, module) => {
		try {
			const res = await axios({
				method: 'patch',
				url: `${process.env.REACT_APP_BASE_URL}/api/v2/course/${courseId}/module/${module._id}`,
				data: {
					moduleName: module.moduleName,
					files: module.files.filter(file => (file !== "FILE::" + fileName))
				},
				headers: { 'Content-Type': 'application/json' }
			})

			await axios.post(`${process.env.REACT_APP_BASE_URL}/deleteFileFromS3`, {
				fileKey: `${courseId}/${module?.moduleName}/${fileName}`, // Pass the file key (path) to the backend
			});
            // Refresh file list after deletion
            getModules();
		} catch(error) {
			console.log(error)
		}
	}

	const mimeTypes = {
		pdf: 'application/pdf',
		doc: 'application/msword',
		docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
		png: 'image/png',
		jpg: 'image/jpeg',
		jpeg: 'image/jpeg',
		gif: 'image/gif',
		txt: 'text/plain',
		csv: 'text/csv',
		xls: 'application/vnd.ms-excel',
		xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
		zip: 'application/zip',
		pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
		ppt: 'application/vnd.ms-powerpoint'
	};

	const handleFileUpload = async (file, module) => {
        if (!file) {
            return
        }

        try {
			const supportedTypes = accept.split(',').map(ext => ext.trim().substring(1));
			const fileName = `${courseId}/${module?.moduleName}/${file.name}`
			const splitFileName = fileName.split('.');
            const ext = splitFileName[splitFileName.length - 1];
            if (!supportedTypes.includes(ext)) {
                alert("file type not supported")
                return;
            }
            
			const blob = new Blob([file], { type: mimeTypes[ext] });
            const formData = new FormData();
            formData.append("file", blob, fileName); // Provide a file name
            formData.append('courseId', courseId); // Course ID
            formData.append('moduleName', module?.moduleName); // Course ID
			
            const response = await axios.post(`${process.env.REACT_APP_BASE_URL}/uploadFileToS3`, formData, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            });
			const res = await axios({
                method: 'patch',
                url: `${process.env.REACT_APP_BASE_URL}/api/v2/course/${courseId}/module/${module._id}`,
				data: {
					moduleName: module.moduleName,
					files: [...module.files, "FILE::" + file.name]
				},
                headers: { 'Content-Type': 'application/json' }
            })
			getModules();
			setSelectedModule(null);
        } catch (error) {
            console.error('Error uploading file: ', error)
        }
    }

	const getModules = async () => {
		try {
            const res = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/api/v2/course/${courseId}/modules`,
                headers: { 'Content-Type': 'application/json' }
            })
            setModules(res.data.modules);
            // setIsLoading(false);
        } catch(error) {
            console.log('ERROR: ', error);
        }
	}

	const createModule = async (moduleTitle) => {
		try {
			const res = await axios({
				method: 'post',
				url: `${process.env.REACT_APP_BASE_URL}/api/v2/course/${courseId}/modules`,
				data: {
					newModuleName: moduleTitle,
				},
				headers: { 'Content-Type': 'application/json' }
			})
			closeBackdrop();
			getModules();
		} catch(error) {
			console.log('ERROR: ', error);
		}
	}

	useEffect(() => {
		if (role !== "") {
			getModules();
		}
	}, [role])

    return (
		<div>
			<div className={`${tabStyles['tab-heading']}`}>
                Modules
				{
					(role === "Instructor" || role === "TA") &&
					<Fab aria-label="add" color="primary" disabled={isLoading} onClick={openCreateModuleModal}>
						<Tooltip title="Create Module">
							<AddIcon />
						</Tooltip>
					</Fab>
				}
			</div>
            <Divider />
			<div className={styles['modules-container']}>
				{
					modules.length > 0 && modules?.map((module) => (
						<Accordion
							defaultExpanded
						>
							<AccordionSummary
								expandIcon={<ExpandMoreIcon />}
								aria-controls="panel1-content"
								id="panel1-header"		
							>
								<div className={styles['module-header']}>
									<div className={styles['module-title']}>
										<DragIndicator />
										<h4>{module.moduleName}</h4>
									</div>
									<div className={styles['module-action-btns']}>
										{
											(role === "Instructor" || role === "TA") && (
												<>
													<AddIcon
														onClick={(e) => {
															e.preventDefault();
															e.stopPropagation();
															setIsUploadFileModalOpen(true);
															setSelectedModule(module);
														}}
													/>
													<Edit 
														onClick={(e) => {
															e.preventDefault();
															e.stopPropagation();
															setIsEditModuleModalOpen(true);
															setEditModuleId(module._id);
															setEditModuleTitle(module.moduleName);
														}}
													/>
													<Delete 
														onClick={(e) => {
															e.preventDefault();
															e.stopPropagation();
															setIsDeleteModuleModalOpen(true);
															setDeleteModuleId(module._id);
															setDeleteModuleTitle(module.moduleName);
														}}
													/>
												</>
											)
										}
									</div>
								</div>
							</AccordionSummary>
							<AccordionDetails>
								<div className={styles['files']}>
									<Files 
										files={module.files.map((file) => (file.substring(6)))} 
										prefix={`${courseId}/${module.moduleName}`} 
										deleteFile={(file) => deleteFile(file, module)}
										role={role}
									/>
								</div>
							</AccordionDetails>
						</Accordion>
					))
				}
			</div>
			<CreateModule
				open={isCreateModuleModalOpen}
				setOpen={setIsCreateModuleModalOpen}
				createModule={createModule} 
			/>
            {
				editModuleId !== -1 && <EditModule open={isEditModuleModalOpen} setOpen={setIsEditModuleModalOpen} moduleTitle={editModuleTitle} setModuleTitle={setEditModuleTitle} handleEdit={handleEditModule} />
			}
			{ 
				deleteModuleId !== -1 && <DeleteModuleModal open={isDeleteModuleModalOpen} setOpen={setIsDeleteModuleModalOpen} moduleTitle={deleteModuleTitle} onDelete={deleteModule} />
			}
			<Backdrop
				sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
				open={isBackdropOpen}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
			<UploadFileModal
				open={isUploadFileModalOpen} 
				handleClose={closeFileModal}
				onSubmit={(file) => handleFileUpload(file, selectedModule)} 
				accept={accept}
			/>
		</div>
	)
}

export default Modules