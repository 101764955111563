/*eslint-disable*/
import React, { useEffect, useRef, useState } from 'react';
import modalStyles from '../../../../css/Modal.module.css';
import DragAndDrop from '../../../commons/DragAndDrop';
import { Backdrop, Button, CircularProgress, Modal } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import fileStyles from './File.module.css'

const FileDisplayModal = ({open, handleClose, file}) => {
    const { id: courseId } = useParams()

    return (
        <Modal
            open={open}
            onClose={handleClose}
        >
            <div className={fileStyles['container']}>
                {file && <iframe title='file' className={fileStyles['container']} src={file} />}
                <Backdrop
                    sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
                    open={file === null}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
            </div>
        </Modal>
    )
}

export default FileDisplayModal