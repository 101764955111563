/*eslint-disable*/
import React, { useEffect, useState } from 'react'
import styles from './CreateCourse.module.css'
import ClearIcon from '@mui/icons-material/Clear';
import Modal from '@mui/material/Modal';
import { Button, createTheme, Fab, TextField, ThemeProvider, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import axios from 'axios';
import dayjs from 'dayjs';
import { DatePicker, LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import DeleteCourseModal from './DeleteCourseModal';

const EditCourse = ({open, setOpen, course, editCourse, deleteCourse}) => {
    const [courseTitle, setCourseTitle] = useState(course?.name);
    const [isValidTitle, setIsValidTitle] = useState(true);
    const [courseStartDate, setCourseStartDate] = useState(dayjs(course?.startDate)); 
    const [isValidStartDate, setIsValidStartDate] = useState(true); 
    const [courseEndDate, setCourseEndDate] = useState(dayjs(course?.endDate)); 
    const [isValidEndDate, setIsValidEndDate] = useState(true); 
    const [courseDescription, setCourseDescription] = useState(course?.description);
    const [isDeleteCourseModalOpen, setIsDeleteCourseModalOpen] = useState(false); 

	const theme = createTheme({
		components: {
			MuiFormLabel: {
				styleOverrides: {
					asterisk: { color: "#d32f2f" },
				},
			},
		},
	})

    const handleClose = () => {
        setOpen(false);
    }

	const handleSubmit = () => {
		if (isValidTitle && isValidStartDate && isValidEndDate) {
			editCourse(courseTitle, courseDescription, courseStartDate, courseEndDate);
			handleClose();
		}
	}

    const handleDelete = () => {
        setIsDeleteCourseModalOpen(true);
    }

    return (
		<div>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className={`${styles['modal-container']}`}>
                    <div className={`${styles['row']}`}>
                        <h1 className={`${styles['modal-heading']}`}>Edit Course</h1>
                        <ClearIcon 
                            onClick={handleClose}
                            sx={{
                                fontSize: "2.5em",
                                cursor: "pointer"
                            }}
                        />
                    </div>
					<div className={`${styles['modal-input-container']}`}>
						<div className={`${styles['to-col']}`} style={{display: "flex", gap: "1em", alignItems: "flex-start"}}>
							<ThemeProvider theme={theme}>
								<TextField
									autoFocus={true}
									id="course-title"
									label="Course Title"
									onBlur={() => {
										if (courseTitle === '') {
											setIsValidTitle(false);
										}
									}}
									onChange={(e) => {setIsValidTitle(true); setCourseTitle(e.target.value);}}
									value={courseTitle}
									fullWidth
									required
									color={!isValidTitle ? 'error' : 'primary'}
									error={!isValidTitle}
									helperText={!isValidTitle ? '* Course title is required' : ''}
								/>
							</ThemeProvider>
                        </div>						

                        <div className={`${styles['to-col']}`} style={{display: "flex", gap: "1em", alignItems: "flex-start"}}>
							<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-gb'>
                                <DatePicker
                                    label="Start Date"
                                    required
                                    id='course-start-date'
                                    value={courseStartDate}
                                    onChange={(newStartDate) => {
                                        setCourseStartDate(newStartDate)
                                    }}
                                    color={!isValidStartDate ? 'error' : 'primary'}
                                    error={!isValidStartDate}
                                    helperText={!isValidStartDate ? 'Start Date is required' : ''}
                                    minTime={dayjs('2022-04-17T00:00:01')}
                                    fullWidth 
                                />
							</LocalizationProvider> 
                            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale='en-gb'>
                                <DatePicker 
                                    label="End Date"
                                    required
                                    id='course-end-date'
                                    value={courseEndDate}
                                    onChange={(newEndDate) => {
                                        setCourseEndDate(newEndDate)
                                    }}
                                    color={!isValidEndDate ? 'error' : 'primary'}
                                    error={!isValidEndDate}
                                    helperText={!isValidEndDate ? 'End Date is required' : ''}
                                    minTime={dayjs('2022-04-17T00:00:01')}
                                    fullWidth 
                                />
							</LocalizationProvider> 
                        </div>
                        <TextField
							fullWidth
							id="course-description"
							label="Course Description"
							onBlur={() => setCourseDescription(courseDescription)}
							onChange={(e) => setCourseDescription(e.target.value)}
							value={courseDescription}
							multiline
							rows={3}
						/>
					</div>

					<div className={`${styles['btn-container']} ${styles['create-quiz-btns']}`} style={{gap: '1em'}}>
                        <Button
                            className={`btn btn-primary ${styles['add-to-course-btn']}`}
                            onClick={() => handleDelete()}
                            variant='contained'
                            size='large'
                            color='error'
                        >
                            Delete
                        </Button>
                        <Button
                            className={`btn btn-primary ${styles['add-to-course-btn']}`}
                            onClick={() => handleSubmit()}
                            variant='contained'
                            size='large'
                        >
                            Save
                        </Button>

                    </div>
                </div>
            </Modal>
            <DeleteCourseModal
                open={isDeleteCourseModalOpen}
                handleClose={() => {setIsDeleteCourseModalOpen(false)}}
                courseTitle={course.name}
                onDelete={() => deleteCourse(course.id)}
            />
        </div>
	)
}

export default EditCourse 