/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { Divider, Fab, FormControlLabel, IconButton, Link, Skeleton, Stack, Switch, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import tabStyles from '../Tab.module.css';
import CreateQuiz from './../../../quiz-new/CreateQuiz';
import EditQuiz from './../../../quiz-new/EditQuiz';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import axios from 'axios';
import { useSelector } from 'react-redux';
import LinkIcon from '@mui/icons-material/Link';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import styles from './Quizzes.module.css'
import { updateQuizMetaData } from '../../../../redux';
import DeleteQuizModal from './DeleteQuizModal';

const Quizzes = ({isLoading, role}) => {
    const { id: courseId } = useParams();
	const [isCreateQuizModalOpen, setIsCreateQuizModalOpen] = useState(false);
	const [isEditQuizModalOpen, setIsEditQuizModalOpen] = useState(false);
	const [editQuizId, setEditQuizId] = useState(-1);
	const [quizzes, setQuizzes] = useState([{}, {}, {}]);
    const [isQuizLoading, setIsQuizLoading] = useState(true);
	const [isDeleteQuizModalOpen, setIsDeleteQuizModalOpen] = useState(false);
	const [deleteQuizId, setDeleteQuizId] = useState(-1);
	const [deleteQuizTitle, setDeleteQuizTitle] = useState(-1);
	
	const handleDelete = async () => {
		try {
			await axios({
                method: 'delete',
                url: `${process.env.REACT_APP_BASE_URL}/api/v2/course/${courseId}/quiz/${deleteQuizId}`,
                headers: { 'Content-Type': 'application/json' }
            });
			setDeleteQuizId(-1);
			setDeleteQuizTitle(-1);
			getQuizzes();
		} catch (error) {
			console.log(error)
		}
    }

	const getQuizzes = async () => {
		try {
            const res = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/api/v2/courses/${courseId}/quizzes`,
                headers: { 'Content-Type': 'application/json' }
            })
            setQuizzes(res.data);
			setIsQuizLoading(false);
        } catch(error) {
            console.log('ERROR: ', error);
        }
	}

	const handlePublish = async (id, publish) => {
		try {
			if (publish) {
				const res = await axios({
					method: 'post',
					url: `${process.env.REACT_APP_BASE_URL}/api/v2/course/${courseId}/quiz/${id}/publish`,
					headers: { 'Content-Type': 'application/json' }
				})
			} else {
				const res = await axios({
					method: 'post',
					url: `${process.env.REACT_APP_BASE_URL}/api/v2/course/${courseId}/quiz/${id}/unpublish`,
					headers: { 'Content-Type': 'application/json' }
				})
			}
			getQuizzes();
		} catch(error) {
			console.log(error)
		}
	}

	useEffect(() => {
		if (role !== "") {
			setIsQuizLoading(true);
			getQuizzes();
		}
	}, [role]);

    return (
		<div>
			<div className={`${tabStyles['tab-heading']}`}>
                Quizzes
				{
					(role === "Instructor" || role === "TA") && (
						<Tooltip title="Add Quiz">
							<Fab aria-label="add" color="primary" disabled={isLoading} onClick={()=>setIsCreateQuizModalOpen(true)}>
								<AddIcon />
							</Fab>
						</Tooltip>	
					)
				}
			</div>
            <Divider />
			<div className={styles['quizzes-container']}>
				{quizzes?.map((quizMeta) => {
					const { title, synopsis, id, isPublished } = quizMeta
					const synopsisTrimmed =
						synopsis?.length > 83
							? `${synopsis.substring(0, 80).trim()}...`
							: synopsis
					return (role !== 'Instructor' && role !== "TA") &&
						!isPublished ? null : (
						<Link
							href={`/quiz/${quizMeta.id}`}
							target="_blank"
							rel="noopener noreferrer"
							style={{
								color: 'inherit',
								textDecoration: 'inherit',
							}}
						>
							<div className={styles['quiz']}>
								<Stack
									direction={'row'}
									display="flex"
									justifyContent="space-between"
								>
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											flexWrap: 'wrap',
										}}
									>
										<IconButton
											aria-label="link"
											// className={classes.linkIcon}
										>
											<LinkIcon />
										</IconButton>
										&nbsp;
										<div>
											<Typography
												fontSize="1.1rem"
												fontWeight="bold"
												sx={{ textAlign: 'left' }}
											>
												{title}
											</Typography>
											<Typography
												variant="subtitle2"
												sx={{ textAlign: 'left' }}
											>
												{synopsisTrimmed}
											</Typography>
										</div>
									</div>
									<Stack direction={'row'}>
										{
											role === 'Instructor' || role === "TA" ? (
												<div
													style={{
														display: 'flex',
														alignItems: 'center',
														gap: '1em'
													}}
												>
													<FormControlLabel 
														control={
															<Switch
																checked={isPublished}
																onChange={() => handlePublish(id, !isPublished)}
																inputProps={{ 'aria-label': 'controlled' }}
																color='success'
															/>
														} 
														sx={{
															margin: 0
														}}
														label="Publish" 
														labelPlacement="start"
													/>
													<EditIcon
														onClick={(e) => {
															e.preventDefault();
															e.stopPropagation();
															setEditQuizId(id);
															setIsEditQuizModalOpen(true); 
														}} 
													/>
													<DeleteIcon
														onClick={(e) => {
															e.preventDefault();
															e.stopPropagation();
															setDeleteQuizId(id);
															setDeleteQuizTitle(title);
															setIsDeleteQuizModalOpen(true);
														}}  
													/>
													
												</div>
											) : (null) 
										}
									</Stack>
								</Stack>
							</div>
						</Link>
					)
				})} 

			</div>
			<CreateQuiz open={isCreateQuizModalOpen} setOpen={setIsCreateQuizModalOpen}/>
            {
				editQuizId !== -1 && <EditQuiz open={isEditQuizModalOpen} getQuizzes={getQuizzes} setOpen={setIsEditQuizModalOpen} quizId={editQuizId} />
			}
			{
				deleteQuizId !== -1 && <DeleteQuizModal open={isDeleteQuizModalOpen} setOpen={setIsDeleteQuizModalOpen} quizTitle={deleteQuizTitle} onDelete={handleDelete} />
			}
		</div>
	)
}

export default Quizzes