/* eslint-disable */
import React, { useEffect, useState } from 'react';
import styles from './Courses.module.css';
import { Link } from '@mui/material';

const CourseThumbnail = ({course}) => {
	const colors = [
		"#ffcdd2",
		"#f8bbd0",
		"#e1bee7",
		"#d1c4e9",
		"#c5cae9",
		"#bbdefb",
		"#b3e5fc",
		"#b2ebf2",
		"#b2dfdb",
		"#c8e6c9"
	]

    const [isHovered, setIsHovered] = useState(false);

    var colorId = 0;
    
    Array.from(course.id.toString()).forEach(char => {
        if (parseInt(char)) {
            colorId += parseInt(char);
        }
    })

    colorId = colorId % 10;

    return (
        <Link 
            href={`/course/${course.id}`} 
            underline="none" rel="noerror noreffer"
        >
            <div 
                style={{
                    backgroundColor: isHovered ? `${colors[colorId]}` : ''
                }}
                className={`${styles['course']}`}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
            >
                {course.name}
            </div>
        </Link>
    )
}

export default CourseThumbnail