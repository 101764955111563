/* eslint-disable */
import React, { useRef, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import Button from '@mui/material/Button'
import SendIcon from '@mui/icons-material/Send'
import CircularProgress from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import Person4Icon from '@mui/icons-material/Person4'
import axios from 'axios'
import { makeStyles } from '@mui/styles'

function CourseDropdown({
    courses,
    selectedCourse,
    handleDropdownClick,
    handleCourseChange,
    dropdownPlaceholder,
}) {
    return (
        <div style={{ padding: '10px', display: 'flex', alignItems: 'center' }}>
            <span
                style={{
                    marginRight: '10px',
                    fontWeight: 'bold',
                    color: '#3f51b5',
                }}
            >
                Course:
            </span>
            <select
                onClick={handleDropdownClick}
                onChange={handleCourseChange}
                value={selectedCourse || 'Please choose a course'}
                style={{
                    height: '40px',
                    width: '300px',
                    padding: '8px',
                    border: '1px solid #ccc',
                    borderRadius: '4px',
                    borderColor: 'black',
                    backgroundColor: 'white',
                    color: 'black',
                    fontSize: '16px',
                    fontFamily: 'Italic',
                }}
            >
                <option value="" disabled hidden>
                    {dropdownPlaceholder}
                </option>
                <option value="Please choose a course">
                    Please choose a course
                </option>
                {courses.map((course) => (
                    <option key={course.id} value={course.id}>
                        {course.name}
                    </option>
                ))}
            </select>
        </div>
    )
}

function VirtualTA() {
    const [question, setQ] = useState('')
    const [error, setError] = useState('')
    const [conversationHistory, setConversationHistory] = useState([])
    const [selectedCourse, setSelectedCourse] = useState(null)
    const [showCourseDropdown, setShowCourseDropdown] = useState(false)
    const conversationRef = useRef(null)
    const userData = useSelector((state) => state.userData)
    const userId = userData.user ? userData.user.id : null
    const [queryInProgress, setQueryInProgress] = useState(false)
    const [initialMessagesShown, setInitialMessagesShown] = useState(false)
    const [dropdownPlaceholder, setDropdownPlaceholder] = useState(
        'Please choose a course'
    )
    const [virtualTAOpenTime, setVirtualTAOpenTime] = useState(false)
    const [showWelcomeMessage, setShowWelcomeMessage] = useState(false)
    const [selectedCourseName, setSelectedCourseName] = useState(null)
    const [courses, setCourses] = useState(null)

    const useStyles = makeStyles(() => ({
        dropdownSect: {
            borderRadius: '100px',
        },
        messageContainer: {
            display: 'flex',
            flexDirection: 'column',
            marginBottom: '10px',
        },
        messageContent: {
            backgroundColor: '#cad8e6',
            borderRadius: '10px',
            padding: '10px',
            margin: '10px',
            marginLeft: '20px',
            marginBottom: '5px',
            alignSelf: 'flex-start',
            maxWidth: '70%',
        },
        iconsContainer: {
            display: 'flex',
            marginLeft: '20px',
            alignSelf: 'flex-start',
            alignItems: 'center',
        },
        chatBotIcon: {
            color: 'blue',
            marginRight: '5px',
        },
        userIcon: {
            color: 'green',
            marginRight: '5px',
        },
        icon: {
            marginRight: '5px',
        },
    }))
    const classes = useStyles()
    useEffect(() => {
        window.scrollTo(0, 0)
        setVirtualTAOpenTime(true)
        setInitialMessagesShown(true)
    }, [])

    const getCourses = async () => {
        try {
            const res = await axios({
                method: 'get',
                url: `${process.env.REACT_APP_BASE_URL}/api/v2/courses`,
                headers: { 'Content-Type': 'application/json' },
            })

            setCourses(res.data)
        } catch (error) {
            console.log('ERROR: ', error)
        }
    }

    useEffect(() => {
        window.scrollTo(0, 0)
        if (
            userData.userFetchCompleted &&
            userData.user &&
            userData.user.id !== undefined
        ) {
            getCourses()
        }

        // Show initial messages after the component is rendered
        if (virtualTAOpenTime) {
            const delay = setTimeout(() => {
                setInitialMessagesShown(true)
            }, 2000) // 2000 milliseconds delay

            return () => clearTimeout(delay)
        }
    }, [userData.userFetchCompleted, userData.user])

    useEffect(() => {
        // Show the course dropdown after the initial messages are shown
        if (initialMessagesShown) {
            const delay = setTimeout(() => {
                setShowCourseDropdown(true)
            }, 2000) // 2000 milliseconds delay

            return () => clearTimeout(delay)
        }
    }, [initialMessagesShown])

    const handleDropdownClick = () => {
        setDropdownPlaceholder('')
    }

    useEffect(() => {
        // This block will be executed when selectedCourse changes
        if (selectedCourse) {
            const selectedCour = courses.find(
                (course) => course.id === selectedCourse
            )
            if (selectedCour) {
                setSelectedCourseName(selectedCour.name)
            }
        }
    }, [selectedCourse])

    useEffect(() => {
        // Scroll to the last question in the conversation
        if (conversationRef.current) {
            const lastQuestion = conversationRef.current.lastChild
            if (lastQuestion) {
                lastQuestion.scrollIntoView({ behavior: 'smooth' })
            }
        }
    }, [conversationHistory])

    const handleQuestionChange = (event) => {
        setQ(event.target.value)
    }

    const LoadingDots = () => {
        const [dots, setDots] = useState('Loading')

        useEffect(() => {
            const intervalId = setInterval(() => {
                setDots((prevDots) => {
                    return prevDots.length >= 18 ? 'Loading' : prevDots + '.'
                })
            }, 500)

            return () => clearInterval(intervalId)
        }, [])

        return (
            <Typography
                variant="body1"
                style={{
                    color: 'white',
                    fontWeight: 'bold',
                    fontStyle: 'italic',
                }}
            >
                {dots}
            </Typography>
        )
    }
    const handleCourseChange = (event) => {
        const courseId = event.target.value
        const selectedCour = courses.find((course) => course.id === courseId)
        if (selectedCour) {
            setSelectedCourseName(selectedCour.name)
        }
        setSelectedCourse(courseId)
        setConversationHistory([])
        setInitialMessagesShown(false)
        setQ('')
        setShowWelcomeMessage(true)
    }
    function WelcomeMessage() {
        return (
            <div>
                <div
                    key="message1"
                    style={{
                        backgroundColor: '#cad8e6',
                        borderRadius: '10px',
                        padding: '10px',
                        margin: '10px',
                        marginLeft: '30px',
                        marginBottom: '15px',
                        alignSelf: 'flex-start',
                        maxWidth: '85%',
                    }}
                >
                    <p style={{ margin: '10px' }}>
                        Hi! I am your myTutor! You may ask any doubts related to
                        any concept as well as course details related queries.
                        Happy Learning!
                    </p>
                    <Person4Icon className={classes.chatBotIcon} />
                </div>
            </div>
        )
    }
    const handleSubmit = async (event) => {
        event.preventDefault()
        setError('')
        setQ('')
        setQueryInProgress(true)

        try {
            const newMessage = {
                question,
                answer: (
                    <>
                        <CircularProgress size={20}></CircularProgress>
                        <LoadingDots />
                    </>
                ), // Placeholder for the answer
                isCourseRelated: '',
                loading: true, 
            }

            setConversationHistory((prevHistory) => [
                ...prevHistory,
                newMessage,
            ])

            let answer = ''
            const prompt1 = `${question}. Answer this question in context of ${selectedCourseName}. "`
            try {
                const response = await fetch(
                    `https://turing.myacademic.org/search/?query=${encodeURIComponent(
                        prompt1
                    )}&course_id=${selectedCourse}`,
                    {
                        method: 'GET',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    }
                )

                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`)
                }

                const data = await response.json()
                answer = data.response
            } catch (error) {
                console.error('Error:', error.message)
            }

            setConversationHistory((prevHistory) =>
                prevHistory.map((message) =>
                    message === newMessage
                        ? {
                              ...message,
                              answer: (
                                  <div
                                      style={{
                                          whiteSpace: 'pre-wrap',
                                          maxWidth: '350px',
                                      }}
                                  >
                                      {answer}
                                  </div>
                              ),
                              loading: false,
                          }
                        : message
                )
            )

            setQ('')
            setQueryInProgress(false)
        } catch (err) {
            console.error(err)
            setError(
                'There was an error processing your request. Please try again.'
            )
            setQueryInProgress(false)
        }
    }

    return (
        <div
            style={{
                paddingTop: '10px',
                backgroundColor: '#f7f7f7',
                display: 'flex',
                flexDirection: 'column',
                height: '100%',
                zIndex: 1105,
            }}
        >
            <h3
                style={{
                    paddingBottom: '14px',
                    paddingLeft: '14px',
                    borderBottom: '0.7px solid grey',
                }}
            >
                {userData?.user?.role === 'Instructor' ? (
                    <span style={{ color: '#3f51b5', marginLeft: '150px' }}>
                        <i>my</i>TA
                    </span>
                ) : (
                    <span style={{ color: '#3f51b5' }}>
                        <i>my</i>Tutor
                    </span>
                )}
            </h3>

            <div
                style={{
                    height: 'calc(100vh - 90px)',
                    overflow: 'auto',
                }}
            >
                {initialMessagesShown && (
                    <div>
                        <div
                            key="message1"
                            style={{
                                backgroundColor: '#cad8e6',
                                borderRadius: '20px',
                                borderBottomLeftRadius: '0',
                                padding: '10px',
                                margin: '10px',
                                marginLeft: '20px',
                                marginBottom: '15px',
                                alignSelf: 'flex-start',
                                maxWidth: '70%',
                            }}
                        >
                            <p style={{ margin: '10px' }}>
                                Hi! I am your virtual teaching assistant. You
                                may ask all your educational doubts here!
                            </p>
                        </div>
                        <Person4Icon className={classes.chatBotIcon} />
                        <div
                            key="message2"
                            style={{
                                backgroundColor: '#cad8e6',
                                borderRadius: '20px',
                                borderBottomLeftRadius: '0',
                                padding: '10px',
                                margin: '10px',
                                marginLeft: '20px',
                                alignSelf: 'flex-start',
                                maxWidth: '70%',
                            }}
                        >
                            <p style={{ margin: '10px' }}>
                                Please choose a course to proceed further...
                            </p>
                        </div>
                        <Person4Icon className={classes.chatBotIcon} />
                    </div>
                )}

                {showWelcomeMessage && <WelcomeMessage />}

                {showCourseDropdown && courses && (
                    <CourseDropdown
                        courses={courses}
                        selectedCourse={selectedCourse}
                        handleDropdownClick={handleDropdownClick}
                        handleCourseChange={handleCourseChange}
                        dropdownPlaceholder={dropdownPlaceholder}
                        className={classes.dropdownSect}
                    />
                )}

                <div
                    ref={conversationRef}
                    style={{ overflow: 'auto', flex: '1' }}
                >
                    {conversationHistory.map((message, indx) => (
                        <div
                            key={indx}
                            style={{ marginBottom: '2px', userSelect: 'text' }}
                        >
                            <div style={{ margin: '0' }}>
                                <p
                                    style={{
                                        backgroundColor: '#e6e6e6',
                                        borderRadius: '20px',
                                        borderBottomRightRadius: '0',
                                        // border: '1px solid black',
                                        padding: '10px',
                                        margin: '10px',
                                        marginLeft: '90px',
                                        marginBottom: '15px',
                                        alignSelf: 'flex-start',
                                        maxWidth: '70%',
                                    }}
                                >
                                    {message.question}
                                </p>
                            </div>

                            <div
                                style={{
                                    paddingBottom: '5px',
                                    marginLeft: '20px',
                                    paddingTop: '1px',
                                    maxWidth: '80%',
                                    userSelect: 'text', // Make the text selectable
                                    zIndex: 1108,
                                }}
                            >
                                <p
                                    style={{
                                        backgroundColor: '#cad8e6',
                                        padding: '10px',
                                        borderRadius: '20px',
                                        borderBottomLeftRadius: '0',
                                        // border: '1px solid black',
                                        margin: '10px',
                                        userSelect: 'text',
                                    }}
                                >
                                    {message.answer}
                                </p>
                                <Person4Icon className={classes.chatBotIcon} />
                            </div>
                        </div>
                    ))}
                </div>
            </div>

            {showCourseDropdown && !initialMessagesShown && (
                <form
                    onSubmit={handleSubmit}
                    style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        padding: '14px',
                        borderTop: '2px solid #BCBCBC ',
                    }}
                >
                    <input
                        type="text"
                        id="question"
                        name="question"
                        placeholder="Write your question..."
                        value={question}
                        onChange={handleQuestionChange}
                        style={{
                            height: '50px',
                            width: '100%',
                            padding: '12px 20px',
                            margin: '0',
                            display: 'inline-block',
                            border: '1px solid #BCBCBC',
                            borderRadius: '50px',
                            boxSizing: 'border-box',
                            outline: 'none',
                            backgroundColor: 'white',
                        }}
                    />
                    {!queryInProgress && (
                        <Button
                            type="submit"
                            variant="contained"
                            endIcon={
                                <SendIcon
                                    style={{
                                        color: question ? 'grey' : 'lightgrey',
                                    }}
                                />
                            }
                            aria-label="send"
                            style={{
                                backgroundColor: '#fff',
                                marginTop: '3px',
                                marginLeft: '8px',
                            }}
                            disabled={!question}
                        />
                    )}
                </form>
            )}

            {error && (
                <div
                    style={{
                        backgroundColor: 'white',
                        padding: '5px',
                        border: '1px solid black',
                    }}
                >
                    <p style={{ backgroundColor: 'white' }}>{error}</p>
                </div>
            )}
        </div>
    )
}

export default VirtualTA
