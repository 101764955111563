/*eslint-disable*/
import React, { useState } from 'react'
import modalStyles from '../../../../css/Modal.module.css';
import ClearIcon from '@mui/icons-material/Clear';
import Modal from '@mui/material/Modal';
import dayjs from 'dayjs';
import { Button, createTheme, Fab, TextField, ThemeProvider, Tooltip } from '@mui/material';

const EditAnnouncement = ({open, setOpen, announcement, editAnnouncement}) => {
    const [announcementTitle, setAnnouncementTitle] = useState(announcement.title); 
    const [isValidTitle, setIsValidTitle] = useState(true); 
    const [announcementBody, setAnnouncementBody] = useState(announcement.body); 
    const [isValidBody, setIsValidBody] = useState(true); 

	const theme = createTheme({
		components: {
			MuiFormLabel: {
				styleOverrides: {
					asterisk: { color: "#d32f2f" },
				},
			},
		},
	
	})

    const handleClose = () => {
        setOpen(false);
    }

	const handleSubmit = () => {
		if (isValidTitle && isValidBody) {
			const date = dayjs();
			const dateStr = date.toString();
			editAnnouncement(announcementTitle, announcementBody, dateStr);
			handleClose();
		}
	}

    return (
		<div>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<div className={`${modalStyles['container']}`}>
					<div className={`${modalStyles['top-container']}`}>
						<h1 className={`${modalStyles['heading']}`}>Edit Announcement</h1>
						<ClearIcon 
							onClick={handleClose}
							sx={{
								fontSize: "2.5em",
								cursor: "pointer"
							}}
						/>
					</div>
					<div className={`${modalStyles['main-container']}`} style={{display: "flex", gap: "1em", flexDirection: "column"}}>
					    <div style={{display: "flex", gap: "1em", alignItems: "flex-start"}}>
							<ThemeProvider theme={theme}>
								<TextField
									autoFocus={true}
									id="module-title"
									label="Announcement Title"
									onBlur={() => {
										if (announcementTitle === '') {
											setIsValidTitle(false);
										}
									}}
									onChange={(e) => {setIsValidTitle(true); setAnnouncementTitle(e.target.value);}}
									value={announcementTitle}
									fullWidth
									required
									color={!isValidTitle ? 'error' : 'primary'}
									error={!isValidTitle}
									helperText={!isValidTitle ? '* Announcement title is required' : ''}
								/>
							</ThemeProvider>
                        </div>	
                        <div style={{display: "flex", gap: "1em", alignItems: "flex-start"}}>
							<ThemeProvider theme={theme}>
								<TextField
									id="module-title"
									label="Announcement Body"
									onBlur={() => {
										if (announcementBody === '') {
											setIsValidBody(false);
										}
									}}
									onChange={(e) => {setIsValidBody(true); setAnnouncementBody(e.target.value);}}
									value={announcementBody}
									fullWidth
									required
									color={!isValidBody ? 'error' : 'primary'}
									error={!isValidBody}
									helperText={!isValidBody ? '* Announcement body is required' : ''}
								/>
							</ThemeProvider>
                        </div>	
					</div>
					<div className={`${modalStyles['button-container']}`}>
						<Button
                            className={`btn btn-primary ${modalStyles['add-to-course-btn']}`}
                            onClick={handleSubmit}
                            variant='contained'
                            size='large'
                        >
                            Post
                        </Button>
					</div>
				</div>
			</Modal>
        </div>
	)
}

export default EditAnnouncement 