/*eslint-disable*/
import React, { useEffect, useState } from 'react';
import { Backdrop, CircularProgress, Fab, Paper, Skeleton, Tooltip } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Divider from '@mui/material/Divider';
import Link from '@mui/material/Link';
import CreateCourse from './CreateCourse';
import globalStyles from '../../css/Page.module.css';
import styles from './Courses.module.css';
import axios from 'axios';
import CourseThumbnail from './CourseThumbnail';
import { useSelector } from 'react-redux';

const Courses = () => {
	const [isLoading, setIsLoading] = useState(true);
	const [isCreateCourseModalOpen, setIsCreateCourseModalOpen] = useState(false);
	const [courses, setCourses] = useState([{},{},{},{},{},{},{},{},{}]);
	const [isBackdropOpen, setIsBackdropOpen] = useState(false);
	const [role, setRole] = useState("");
	const userData = useSelector((state) => state.userData)

	const openCreateCourseModal = () => {
		setIsCreateCourseModalOpen(true);
	}

	const openBackdrop = () => {
		setIsBackdropOpen(true);
	}

	const closeBackdrop = () => {
		setIsBackdropOpen(false);
	}

	const getCourses = async () => {
		// GET API Call
		// setTimeout(() => {
		// 	const res = [
		// 		{id: 1, isPublished: true, name: "Web Development"},
		// 		{id: 2, isPublished: true, name: "DBMS"},
		// 		{id: 3, isPublished: true, name: "DSA"},
		// 		{id: 4, isPublished: true, name: "Machine Learning"},
		// 		{id: 5, isPublished: true, name: "Computer Architecture"},
		// 		{id: 6, isPublished: true, name: "OOPs"},
		// 		{id: 7, isPublished: true, name: "Computer Organisation"},
		// 		{id: 8, isPublished: true, name: "Internet Technology"},
		// 		{id: 9, isPublished: true, name: "Computer Networks"},
		// 		{id: 10, isPublished: true, name: "Mathematics"},
		// 		{id: 11, isPublished: true, name: "Demo"},
		// 	];
		// 	setCourses(res);
		// 	setIsLoading(false);
		// }, 5000);

		try {
			const res = await axios({
				method: 'get',
				url: `${process.env.REACT_APP_BASE_URL}/api/v2/courses`,
				headers: { 'Content-Type': 'application/json' }
			})

			setCourses(res.data);
			setIsLoading(false);
		} catch(error) {
			console.log('ERROR: ', error);
		}
	}

	const createCourse = async (name, desc, startDate, endDate, isPublished) => {
		// POST API Call
		openBackdrop();
		// setTimeout(() => {
		// 	setCourses(prevCourses => (
		// 		[...prevCourses, {id: prevCourses.length + 1, name, startDate, endDate, isPublished}]
		// 	));
		// }, 5000);
		try {
			const res = await axios({
				method: 'post',
				url: `${process.env.REACT_APP_BASE_URL}/api/v2/courses`,
				data: {
					name: name,
					description: desc,
					startDate: startDate, 
					endDate: endDate,
					isPublished: isPublished,
				},
				headers: { 'Content-Type': 'application/json' }
			})
			closeBackdrop();
			getCourses();
		} catch(error) {
			console.log('ERROR: ', error);
		}
	}

	useEffect(() => {
		// Get Courses from server
		getCourses();
		
	}, []);

	useEffect(() => {
		if (
			userData.userFetchCompleted &&
			userData.user &&
			userData.user.id !== undefined
		) {
			setRole(userData.user.role);
		}
	}, [userData.userFetchCompleted, userData.user])

	return (
		<div className={`${globalStyles['main-container']} ${styles['main-container']}`}>
			<div className={`${globalStyles['main-heading']}`}>
				Courses
				{
					role === "Instructor" && (
						<Tooltip title="Create Course">
							<Fab aria-label="add" color="primary" onClick={openCreateCourseModal} disabled={isLoading}>
								<AddIcon />
							</Fab>
						</Tooltip>
					)
				}
			</div>
			<Divider />
			<div className={`${styles['courses-list-container']}`}>
				{
					courses.map((course, i) => isLoading ? (
						<Skeleton key={i} height={'125px'} className='skeleton-card' variant='rounded' />
					) : (
						<CourseThumbnail key={i} course={course} />
					))
				}
			</div>
			<CreateCourse 
				open={isCreateCourseModalOpen} 
				setOpen={setIsCreateCourseModalOpen}
				createCourse={createCourse} 
			/>
			<Backdrop
				sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.drawer + 1 })}
				open={isBackdropOpen}
			>
				<CircularProgress color="inherit" />
			</Backdrop>
		</div>
	)
}

export default Courses