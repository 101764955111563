/*eslint-disable*/
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { googleLogin as googleLoginRedux } from '../redux'
import axios from 'axios'

function GoogleLogin({ googleLogin }) {
    const [oauthId, setOauthId] = useState('');
    useEffect(() => {
        axios.get(`${process.env.REACT_APP_BASE_URL}/oauth-key`)
            .then((res) => {
                if (res.data.googleClientId) {
                    setOauthId(res.data.googleClientId);
                }
            })
            .catch((err) => console.error('Failed to fetch OAuth key:', err));
    }, []);
    useEffect(() => {
        // eslint-disable-next-line no-undef
        if (oauthId){
            google.accounts.id.initialize({
                client_id: oauthId,
                callback: (response) => {
                    googleLogin(response.credential)
                },
            })
            // eslint-disable-next-line no-undef
            google.accounts.id.renderButton(document.getElementById('signInDiv'), {
                theme: 'outline',
                size: 'large',
            })
        }
    }, [oauthId])

    return <div id="signInDiv" style={{ textAlign: '-webkit-center' }} />
}

GoogleLogin.propTypes = {
    googleLogin: PropTypes.func.isRequired,
}

const mapStateToProps = () => {
    return {}
}

const mapDispatchToProps = (dispatch) => {
    return {
        googleLogin: (jwtToken) => dispatch(googleLoginRedux(jwtToken)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GoogleLogin)
