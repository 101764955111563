/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Button, Divider, Fab, Skeleton, Tab, Tabs, Tooltip } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import styles from './Files.module.css';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import FileDisplayModal from './FileDisplayModal';
import { Document } from 'react-pdf';
import { Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import { Delete, DragIndicator } from '@mui/icons-material';
import DeleteFileModal from './DeleteFileModal';
import axios from 'axios';

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.mjs`;

const Files = ({files, deleteFile, getFiles, prefix, editPermission, role}) => {
	const [isFileOpen, setIsFileOpen] = useState(false);
	const [selectedFileKey, setSelectedFileKey] = useState(null);
	const [selectedFileBlob, setSelectedFileBlob] = useState(null);
	const [isDeleteFileModalOpen, setIsDeleteFileModalOpen] = useState(false);
	const [fileToBeDeleted, setFileToBeDeleted] = useState(false);

	const fileIcons = {
		'pdf': '/icons/pdf_icon.svg',
		'doc': '/icons/doc_icon.svg',
		'docx': '/icons/doc_icon.svg',
		'ppt': '/icons/ppt_icon.svg',
		'pptx': '/icons/ppt_icon.svg',
		'xlsx': '/icons/xlsx_icon.svg',
		'txt': '/icons/txt_icon.svg'
	}

	const getFileExtension = (file) => {
		const splitName = getFileName(file).split('.');
		return splitName[splitName.length - 1];
	}

	const getFileName = (fileUrl) => {
		const splitUrl = fileUrl.split('/');
		return splitUrl[splitUrl.length - 1];
	}

	const closeFile = () => {
		setIsFileOpen(false);
	}

	const generateGoogleDocsViewerUrl = (s3Url) => {
		return `https://view.officeapps.live.com/op/view.aspx?src=${encodeURIComponent(
                    s3Url)}`;
	};

	const handleOpenFile = async (fileKey) => {
		try {
			// Make a POST request to get the signed URL from the backend
			const response = await axios.post(
				`${process.env.REACT_APP_BASE_URL}/getDownloadUrlFromS3`,
				{ fileKey },
				{ responseType: 'blob' } // Ensure the response is treated as a binary Blob
			);

			setSelectedFileKey(fileKey);
			setSelectedFileBlob(response.data);
			setIsFileOpen(true);
		} catch (error) {
			console.error('Error getting download URL:', error);
		}
	};


	const closeDeleteFileModal = () => {setIsDeleteFileModalOpen(false)}

    return (
		<div className={styles['files']}>
			{
				files.map((file) => (
					<div 
						className={styles['file']} 
						onClick={() => {
							handleOpenFile(`${prefix}/${file}`)
						}}	
					>
						<div className={styles['file-icon-title-container']}>
							<DragIndicator />
							<img className={styles['file-icon']} src={fileIcons[getFileExtension(file)]} />
							{getFileName(file).substring(0, 50)}
						</div>
						{
							(role === "Instructor" || role === "TA") &&
							<Tooltip title='Delete file'>
								<Delete 
									onClick={(e) => {
										e.preventDefault();
										e.stopPropagation();
										setIsDeleteFileModalOpen(true);
										setFileToBeDeleted(file)}
									} 
								/>
							</Tooltip>
						}
					</div>
				))
			}
			<FileDisplayModal
				open={isFileOpen}
				handleClose={closeFile}
				fileKey={selectedFileKey}
				fileBlob={selectedFileBlob}
			/>
			<DeleteFileModal
				open={isDeleteFileModalOpen}
				handleClose={closeDeleteFileModal}
				file={fileToBeDeleted}
				getFiles={getFiles}
				onDelete={deleteFile}
			/>
		</div>
	)
}

export default Files